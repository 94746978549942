import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil, map } from 'rxjs/operators';
import { Subject, BehaviorSubject, Observable, VirtualTimeScheduler } from 'rxjs';
import { HttpModule, Http, Response } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { OrderService } from 'src/app/service/order.service';
import { AppService } from 'src/app/service/app.service';
import { OrderedFoodModel, OrderModel } from 'src/app/model/order';
import * as _ from 'lodash';

@Component({
    selector: 'app-shopping-cart',
    // templateUrl: './shopping-cart.component.html',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {

    isLoading: boolean = false;
    order: OrderModel;
    orderedFoods: OrderedFoodModel[];
    comment: string = '';
    eatMode = 'home';

    myTime = new Date();
    scheduleTimes = ['dsd', 'sd'];

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _orderService: OrderService,
        private _router: Router,
        private _appService: AppService,
        private toastr: ToastrService
    ) {
        this.order = new OrderModel({});
        this.orderedFoods = [];
        this.eatMode = this._orderService.myOrder.isReal ? 'home' : 'collection';
        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this._orderService.onChangeMyOrder
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((myOrder: OrderModel) => {
                if (myOrder) {
                    // const { times, isServing } = this._orderService.checkWorkHour('');
                    // if (times) this.scheduleTimes = times;

                    this.order = new OrderModel({});
                    this.orderedFoods = [];
                    this.order = myOrder;
                    if (myOrder.cartItemList != undefined) {
                        this.orderedFoods = _.cloneDeep(myOrder.cartItemList);
                    }
                }
            });
    }

    onQuantityUp(food: OrderedFoodModel, index) {
        this._orderService.updateCartItem(index, true);
    }

    onQuantityDown(food: OrderedFoodModel, index) {
        if (food.foodQuantity < 2) {
            this._orderService.removeCartItem(food, index);
            return;
        }
        this._orderService.updateCartItem(index, false);
    }

    onCheckout() {
        if (!this._orderService.myOrder.cartItemList || this._orderService.myOrder.cartItemList.length < 1) {
            this.toastr.error('Your shopping cart is empty!');
            return;
        }

        this._orderService.myOrder.comment = this.comment;
        this._router.navigate(['/checkout']);

    }

    onChangedEatMode(type) {
        this.eatMode = type;
        if (this.eatMode == 'home') {
            this._orderService.myOrder.isReal = true;
        } else {
            this._orderService.myOrder.isReal = false;
        }
    }

    onDeliveryAndCheckout() {
        this.onChangedEatMode('home');
        this.onCheckout();
    }

    onCollectionAndCheckout() {
        this.onChangedEatMode('collection');
        this.onCheckout();
    }

    onTimeSelect(e) {
        this._orderService.myOrder.scheduledTime = e;
    }

    ngOnDestroy() {

    }
}
