export const firebaseConfig = {

  // apiKey: "AIzaSyD7uYF7G4PiWOull3sFGN-pY8eqPhLRhPs",
  // authDomain: "acelinecomms.firebaseapp.com",
  // databaseURL: "https://acelinecomms-default-rtdb.firebaseio.com",
  // projectId: "acelinecomms",
  // storageBucket: "acelinecomms.appspot.com",
  // messagingSenderId: "135302655921",
  // appId: "1:135302655921:web:89270d5723db4d185c5790",
  // measurementId: "G-X3Z29C5YS9"

  // apiKey: "AIzaSyAOjjXNrIR5-pOS4JXzLXVF5LONnbj9UgA",
  // authDomain: "temppos-dcf9d.firebaseapp.com",
  // databaseURL: "https://temppos-dcf9d-default-rtdb.firebaseio.com",
  // projectId: "temppos-dcf9d",
  // storageBucket: "temppos-dcf9d.appspot.com",
  // messagingSenderId: "941330750771",
  // appId: "1:941330750771:web:6709b5d615553a69c27246",
  // measurementId: "G-6F224LKTBK"

  // apiKey: "AIzaSyDHarPn9XMyshEI3cb7uARk2fdB2W2_A_E",
  // authDomain: "yaren-chingford.firebaseapp.com",
  // databaseURL: "https://yaren-chingford-default-rtdb.firebaseio.com",
  // projectId: "yaren-chingford",
  // storageBucket: "yaren-chingford.appspot.com",
  // messagingSenderId: "701571210618",
  // appId: "1:701571210618:web:275d55f070ddddca960ac3",
  // measurementId: "G-3L037RTXFL"

  // apiKey: "AIzaSyAIGqTqyWMThW88u451OEVKu_66Znnpj0k",
  // authDomain: "nick-s-pizza.firebaseapp.com",
  // databaseURL: "https://nick-s-pizza-default-rtdb.firebaseio.com",
  // projectId: "nick-s-pizza",
  // storageBucket: "nick-s-pizza.appspot.com",
  // messagingSenderId: "1009598420540",
  // appId: "1:1009598420540:web:f97eac39ebb8f027589539",
  // measurementId: "G-SLVNB5NBHZ"

  // apiKey: "AIzaSyCNhD1tTt2aWB1WHpnAxSXGiXCJ21oMZbA",
  // authDomain: "simply-scrumptious.firebaseapp.com",
  // databaseURL: "https://simply-scrumptious-default-rtdb.europe-west1.firebasedatabase.app",
  // projectId: "simply-scrumptious",
  // storageBucket: "simply-scrumptious.appspot.com",
  // messagingSenderId: "265079308875",
  // appId: "1:265079308875:web:d45d65f25de91aee3443ce",
  // measurementId: "G-BY4E0F6SKJ"

  // apiKey: "AIzaSyDjqKkGzvoIdDSKCGrgBvw1CYw3vFGxBL0",
  // authDomain: "superpos-9bf75.firebaseapp.com",
  // databaseURL: "https://superpos-9bf75-default-rtdb.firebaseio.com",
  // projectId: "superpos-9bf75",
  // storageBucket: "superpos-9bf75.appspot.com",
  // messagingSenderId: "563845424995",
  // appId: "1:563845424995:web:fb570400abeed921edd556",
  // measurementId: "G-FQ9TF15JVX"

  // apiKey: "AIzaSyAS3FtOHc50MMy4VS-draYcsOjjb736isU",
  // authDomain: "screenmoove-5ab1d.firebaseapp.com",
  // databaseURL: "https://screenmoove-5ab1d-default-rtdb.firebaseio.com",
  // projectId: "screenmoove-5ab1d",
  // storageBucket: "screenmoove-5ab1d.appspot.com",
  // messagingSenderId: "107768196100",
  // appId: "1:107768196100:web:2d88991b6f07512ee29951",
  // measurementId: "G-EDHYQNL9EK"

  // apiKey: "AIzaSyCDbA0f6uZ5ihWJtzPMgJZWBQAcdZy_A2A",
  // authDomain: "to-kafe-tis-xaras.firebaseapp.com",
  // databaseURL: "https://to-kafe-tis-xaras-default-rtdb.firebaseio.com",
  // projectId: "to-kafe-tis-xaras",
  // storageBucket: "to-kafe-tis-xaras.appspot.com",
  // messagingSenderId: "866280386525",
  // appId: "1:866280386525:web:9f62bb8d90fc7174d21034",
  // measurementId: "G-DLLPH2CPDE"

  // apiKey: "AIzaSyB2tEb_yfy8sSCAWP8zbxmeqvGZEMpiKgg",
  // authDomain: "three-guys-hayes.firebaseapp.com",
  // databaseURL: "https://three-guys-hayes-default-rtdb.firebaseio.com",
  // projectId: "three-guys-hayes",
  // storageBucket: "three-guys-hayes.appspot.com",
  // messagingSenderId: "1019637343097",
  // appId: "1:1019637343097:web:f43c8e5593fbd1888c05a8",
  // measurementId: "G-XRHWZY3Z6E"

  // apiKey: "AIzaSyA6ru3EgFoEG7vmfVOhfFPdHfjWKGpB5u4",
  // authDomain: "sibfishnchips.firebaseapp.com",
  // databaseURL: "https://sibfishnchips-default-rtdb.firebaseio.com",
  // projectId: "sibfishnchips",
  // storageBucket: "sibfishnchips.appspot.com",
  // messagingSenderId: "591935587247",
  // appId: "1:591935587247:web:3a4331601021641ba9a55a",
  // measurementId: "G-NY1X859ZGS"

  apiKey: "AIzaSyBxBWJC6383u7xXfR6QLxbQYpL8uyok5t4",
  authDomain: "daniel-sushi.firebaseapp.com",
  databaseURL: "https://daniel-sushi.firebaseio.com",
  projectId: "daniel-sushi",
  storageBucket: "daniel-sushi.appspot.com",
  messagingSenderId: "894331197079",
  appId: "1:894331197079:web:4df74ed0df112bbf8f3bd2",
  measurementId: "G-BVBDKV9FLJ"


  // apiKey: "AIzaSyAHO6U-Pg_TbDHAHN4tqXZmew6pALC7Z7Q",
  // authDomain: "citrus-e0284.firebaseapp.com",
  // databaseURL: "https://citrus-e0284-default-rtdb.firebaseio.com",
  // projectId: "citrus-e0284",
  // storageBucket: "citrus-e0284.appspot.com",
  // messagingSenderId: "7323949503",
  // appId: "1:7323949503:web:ace57c0c89f8e977b1290a",
  // measurementId: "G-K8LF6CQ9G4"

  // apiKey: "AIzaSyDEGfN-MXQAYq8LwPD3YDM4HUSCuTMiOFs",
  // authDomain: "kaji-corner.firebaseapp.com",
  // databaseURL: "https://kaji-corner-default-rtdb.firebaseio.com",
  // projectId: "kaji-corner",
  // storageBucket: "kaji-corner.appspot.com",
  // messagingSenderId: "1066049605798",
  // appId: "1:1066049605798:web:86b2a2ac62e680105f6467",
  // measurementId: "G-FR2JYBLEW9"


  // apiKey: "AIzaSyCurYoOxd7OO6aU57QG8SRAIautYd1dG30",
  // authDomain: "mexifun-119da.firebaseapp.com",
  // databaseURL: "https://mexifun-119da.firebaseio.com",
  // projectId: "mexifun-119da",
  // storageBucket: "mexifun-119da.appspot.com",
  // messagingSenderId: "1081209990923",
  // appId: "1:1081209990923:web:db5ac5af807db6a1fd7c0e",
  // measurementId: "G-QGFRT3FQCV"

  // apiKey: "AIzaSyCSUNJdxE9R6jwcLn8HquyrPZm3vctvBDg",
  // authDomain: "nefis-ocakbasi.firebaseapp.com",
  // databaseURL: "https://nefis-ocakbasi-default-rtdb.firebaseio.com",
  // projectId: "nefis-ocakbasi",
  // storageBucket: "nefis-ocakbasi.appspot.com",
  // messagingSenderId: "765764606244",
  // appId: "1:765764606244:web:b3a9f5b19897d432e9fbb1",
  // measurementId: "G-EKVHGY4LNB"

  // apiKey: "AIzaSyDU3JvjotTFSETyAH74-XMzg2JwOY8Zvd4",
  // authDomain: "to-steki-tis-marikas.firebaseapp.com",
  // databaseURL: "https://to-steki-tis-marikas-default-rtdb.firebaseio.com",
  // projectId: "to-steki-tis-marikas",
  // storageBucket: "to-steki-tis-marikas.appspot.com",
  // messagingSenderId: "846862838769",
  // appId: "1:846862838769:web:64198701540300ae0bf250",
  // measurementId: "G-XPGZDGL2SE"


  // apiKey: "AIzaSyAJOGfDs-f6pOU0sEna4jCH8ngG5_uMVoY",
  // authDomain: "santorini-chichester.firebaseapp.com",
  // databaseURL: "https://santorini-chichester-default-rtdb.firebaseio.com",
  // projectId: "santorini-chichester",
  // storageBucket: "santorini-chichester.appspot.com",
  // messagingSenderId: "69265809677",
  // appId: "1:69265809677:web:5107caf0307d9b51591044",
  // measurementId: "G-GBD4NH591J"


  // apiKey: "AIzaSyCmdzrjCpn72WgZmYnMQucnTaA5Ryq0f0Q",
  // authDomain: "basilicogr-13d37.firebaseapp.com",
  // databaseURL: "https://basilicogr-13d37-default-rtdb.firebaseio.com",
  // projectId: "basilicogr-13d37",
  // storageBucket: "basilicogr-13d37.appspot.com",
  // messagingSenderId: "381591342921",
  // appId: "1:381591342921:web:9ce795cb91e319623cd498",
  // measurementId: "G-MS9C80C66K"


  // apiKey: "AIzaSyDi0X08Un7pAevtTmDE1n6v5X1mp3o9htc",
  // authDomain: "harbour-fish-and-chips-635b7.firebaseapp.com",
  // databaseURL: "https://harbour-fish-and-chips-635b7-default-rtdb.firebaseio.com",
  // projectId: "harbour-fish-and-chips-635b7",
  // storageBucket: "harbour-fish-and-chips-635b7.appspot.com",
  // messagingSenderId: "617870170490",
  // appId: "1:617870170490:web:ff970a2912634ecc441ec3",
  // measurementId: "G-BCVXGTCTLE"
}
