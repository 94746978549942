import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/functions';


import { AppComponent } from './app.component';



import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

import { environment } from '../environments/environment';
import { MessagingService } from 'src/app/service/messaging.service';
import { NgxPayPalModule } from 'ngx-paypal';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ShoppingModule } from './routes/shopping/shopping.module';


// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import { AppInitService } from './config';
import { HeaderComponent } from './layout/header/header.component';
import { MenuModule } from './routes/menu/menu.module';
import { BookingModule } from './routes/booking/booking.module';
import { MenuComponent } from './routes/menu/list/menu.component';
import { BookingComponent } from './routes/booking/booking/booking.component';

export function init_app(appLoadService: AppInitService) {
    return () => appLoadService.init().then(event => {
        // CONSTANT.t2 = event[6].name
    });
}

@NgModule({
    declarations: [
        AppComponent,

    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        CarouselModule,
        ShoppingModule,

        // Firebase module
        AngularFirestoreModule.enablePersistence(),
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        AngularFireMessagingModule,
        AngularFireFunctionsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),

        NgxPayPalModule,

        TranslateModule.forRoot({
            loader: {
                provide: APP_INITIALIZER,
                useFactory: (init_app),
                deps: [AppInitService],
                multi: true
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        { provide: FirestoreSettingsToken, useValue: {} },
        MessagingService,
        AppInitService,
        AngularFirestore
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
