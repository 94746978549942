import { Component, OnInit, ViewChild, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
const screenfull = require('screenfull');
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';

import { UserAuthService } from 'src/app/service/user-auth.service'
import { AppService } from 'src/app/service/app.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { LoginComponent } from '../../routes/auth/login/login.component';
import { FBUserModel } from 'src/app/model/user';
import { OrderedFoodModel, OrderModel } from 'src/app/model/order';
// import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CONSTANT, customData, SERVICE } from '../../service/constant';
import secureLS from 'secure-ls';
import { OrderService } from 'src/app/service/order.service';
import { ToastrService } from 'ngx-toastr';
//import defaultLanguage from 'src/assets/i18n/en.json';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    router: Router;
    isLogin = false;
    orderedFoods: OrderedFoodModel[];

    isNavSearchVisible: boolean;
    modalRef: BsModalRef;
    private _unsubscribeAll: Subject<any>;
    lang: any;
    count: boolean;
    options: string;
    logo: any = CONSTANT.logo || '';
    slogo: any = CONSTANT.slogo || '';
    headerMenu: any = [];
    enableBooking: boolean = SERVICE.booking;
    enableOrder: boolean = SERVICE.order;
    enableMenuList: boolean = SERVICE.enableMenuList
    // Theme Color
    color: string = CONSTANT.color
    colorBooktable: string = CONSTANT.headerBtncolor


    transactionItems: any = [];
    mobileMenu: boolean = false
    miniCart: boolean = false;

    constructor(public menu: MenuService,
        public userblockService: UserblockService,
        public settings: SettingsService,
        public injector: Injector,
        private _authService: UserAuthService,
        private _appService: AppService,
        private toastr: ToastrService,
        private _router: Router,
        private _orderService: OrderService,
        // private translate: TranslateService,
        private modalService: BsModalService) {

        CONSTANT.mainmenu.forEach(element => {
            if (element.enable) {
                this.headerMenu.push(element)
            }
        });


        this.transactionItems = {}
        this.transactionItems.total = 0
        this.transactionItems.items = []
        this.transactionItems.noitems = 0

        this._orderService.returnTransactionItems().subscribe(items => {
            this.transactionItems = items
        })
        //  this.lang 
        // this.translate.setTranslation(this.lang,'en');
        //  this.translate.setDefaultLang(this.lang);

        // show only a few items on demo
        this.isLogin = this._authService.isAuthenticated()
        if (this.isLogin) {
            this.menuItems = menu.getMenu().slice(0, 5); // for horizontal layout
        } else {
            this.menuItems = menu.getMenu().slice(0, 3); // for horizontal layout
        }

        this._unsubscribeAll = new Subject();
        this.orderedFoods = [];

        this._appService.isConnetionExist().then(res => {
            if (res == 0) {
                this.logo = '/assets/images/core/1.png';
                this.slogo = '/assets/images/core/11.png';
            }
        })

    }
    hideBtn() {
        document.getElementById('tableBook').classList.remove('active');
        this.router.navigate(['/booking'])
    }
    languageTranslate($event) {

        var language = $event.target.value

        if ($event != undefined) {
            // var language = $event.target.value
        }

        window.location.reload()
        setTimeout(function () {
            this.useLanguage(language)
        }, 500);
        localStorage.setItem('lang', language)
    }
    useLanguage(language: string): void {
        // this.translate.use(language);
        this.options = language
        //if(language == 'en'){
        // document.getElementById('en').setAttribute('selected','true')
        //}else{
        //   document.getElementById('gr').setAttribute('selected','true')
        //}
    }
    clear() {
        localStorage.clear()
        window.location.reload()
    }

    removeCart(idx) {
        let _t = this;
        this.transactionItems.items.splice(idx, 1)
        this._orderService.updateTransactionPrices(true, this.transactionItems).then(transactioItem => {
            _t.transactionItems = transactioItem;
        })
    }

    fixedPrice(price) {
        if (price == 0) { return price; }
        return parseFloat(price).toFixed(2)
    }

    ngOnInit() {
        localStorage.clear()
        //  var lang = localStorage.getItem('lang')
        //  this.useLanguage(lang)
        var cartno = localStorage.getItem('cart_count')
        this.count = JSON.parse(cartno)
        this.isNavSearchVisible = false;

        this.router = this.injector.get(Router);

        // Autoclose navbar on mobile when route change
        this.router.events.subscribe((val) => {
            // scroll view to top
            window.scrollTo(0, 0);
            // close collapse menu
            this.navCollapsed = true;
        });



        this._authService.onUserInfoChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((fbUser: FBUserModel) => {
                if (fbUser.uid) {
                    this.isLogin = true;
                    this.menuItems = this.menu.getMenu().slice(0, 5); // for horizontal layout
                } else {
                    this.isLogin = false;
                    this.menuItems = this.menu.getMenu().slice(0, 3); // for horizontal layout
                }
            });

        var cart = localStorage.getItem('mexicart');
        var cart_qty = 0;
        if (cart != null) {
            console.log('mexicart' + JSON.stringify(cart));
            var mexicart1 = JSON.parse(cart);
            for (var i = 0; i < mexicart1.length; i++) {
                cart_qty += mexicart1[i].foodQuantity;
                this.orderedFoods.push(mexicart1[i]);
            }

            var ele = document.getElementById('cart_count');
            cart = JSON.parse(cart);
            ele.innerHTML = '' + cart_qty;
        }

        var ls = new secureLS({ encodingType: 'aes' });
        let foodItems = ls.get('vederohsik').data;


        // document.getElementById('cart_count').innerHTML = foodItems.noitems;
    }

    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }

    signOut() {
        this._authService.logout().then(() => {
            this.isLogin = false;
            this._appService.curUser = null;
            this.menuItems = this.menu.getMenu().slice(0, 3);
            this._router.navigate(['/home']);
        })
    }

    signIn() {
        this.modalRef = this.modalService.show(LoginComponent, {
            initialState: {
                title: 'Food Detail'
            }
        });
        // this._router.navigate(['/login']);
    }

    ngOnDestroy() {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    checkout() {
        if (customData.date == '') {
            this.toastr.info('please select date')
        }
        else if (customData.time == '') {
            this.toastr.info('please select time')
        }
        else {
            this._router.navigate(['/checkout'])
        }
        // this.menuComponent.proceedCheckout(this.transactionItems)
    }

    hrefCart() {
        this._router.navigate(['/menu']).then(() => {
            setTimeout(() => {
                document.getElementById("sidebar_fixed").scrollIntoView()
            }, 1000)
        })

    }
}
