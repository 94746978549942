/*
Collections
 */
export const COLLECTION_USERS = 'Users';
export const COLLECTION_CATEGORY = 'Category';
export const COLLECTION_CONFIGURATION = 'Configuration';
export const COLLECTION_POPULAR = 'MostPopular';
export const COLLECTION_BESTDEAL = 'BestDeals';
export const COLLECTION_ORDER = 'Order';
export const COLLECTION_BOOKING = 'Booking';
export const COLLECTION_TOKENS = 'fcmToken';
export const COLELCTION_FOODS = 'Foods'
