export class FBUserModel{
    uid?: string;
    name?: string;
    email: string;
    address?: string;
    houseNumber?: string;
    postCode: string;
    phone?: string;
    constructor(uid: string, data: any){
        data = data || {};
        this.uid = uid || '';
        this.name = data.name || '';
        this.email = data.email || '';
        this.address = data.address || '';
        this.houseNumber = data.houseNumber || '';
        this.postCode = data.postCode || '';
        this.phone = data.phone || '';
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}
