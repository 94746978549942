import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import moment from 'moment';

import { COLLECTION_ORDER } from 'src/app/model/constants'
import { OrderedFoodModel, OrderModel } from 'src/app/model/order'
import { AddonModel } from '../model/food';
import { CustomerModel } from '../model/customer';
import { AppService } from 'src/app/service/app.service';
import { MessagingService } from './messaging.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import secureLS from 'secure-ls';
import { CONSTANT } from './constant';
import { UniqueIdGenerator } from './uniqueIdGenerator';
declare var $;


@Injectable({
    providedIn: 'root'
})
export class OrderService {

    myOrder: OrderModel;
    customerModel: CustomerModel;
    allCustomers: any;
    allOrders: any;
    onChangeMyOrder: BehaviorSubject<any>;

    maxOrderAmount: number;
    myPastOrders: OrderModel[];
    // orderCollectionRef: any;
    lastOrderId: number;
    lastOrderIds: [];
    lastOrderIds1: [];
    orderKey: string;
    allowOrder: number;
    maxOrder: number;
    allowSunday: number;
    allowMonday: number;
    allowTuesday: number;
    allowWednesday: number;
    allowThursday: number;
    allowFriday: number;
    allowSaturday: number;
    maxCollect_12: number;
    maxCollect_13: number;
    maxCollect_14: number;
    maxCollect_15: number;
    maxCollect_18: number;
    maxCollect_19: number;
    maxCollect_20: number;
    maxCollect_21: number;
    maxCollect_22: number;
    maxDel_12: number;
    maxDel_13: number;
    maxDel_14: number;
    maxDel_15: number;
    maxDel_18: number;
    maxDel_19: number;
    maxDel_20: number;
    maxDel_21: number;
    maxDel_22: number;
    eatMode = 'any';
    orderCount: number;
    maxMiles: number;
    scheduleTimesList = [
        {
            startTime: { h: 12, m: 0 },
            endTime: { h: 15, m: 0 },

            startTime1: { h: 18, m: 0 },
            endTime1: { h: 22, m: 0 }
        },
        {
            startTime: { h: 12, m: 0 },
            endTime: { h: 15, m: 0 },

            startTime1: { h: 18, m: 0 },
            endTime1: { h: 22, m: 0 }
        },
        {
            startTime: { h: 0, m: 0 },
            endTime: { h: 0, m: 0 },

            startTime1: { h: 0, m: 0 },
            endTime1: { h: 0, m: 0 }
        },
        {
            startTime: { h: 12, m: 0 },
            endTime: { h: 15, m: 0 },

            startTime1: { h: 18, m: 0 },
            endTime1: { h: 22, m: 0 }
        },
        {
            startTime: { h: 12, m: 0 },
            endTime: { h: 15, m: 0 },

            startTime1: { h: 18, m: 0 },
            endTime1: { h: 22, m: 0 }
        },
        {
            startTime: { h: 12, m: 0 },
            endTime: { h: 15, m: 0 },

            startTime1: { h: 18, m: 0 },
            endTime1: { h: 22, m: 0 }
        },
        {
            startTime: { h: 12, m: 0 },
            endTime: { h: 15, m: 0 },

            startTime1: { h: 18, m: 0 },
            endTime1: { h: 22, m: 0 }
        }
    ];
    dialogRef: BsModalRef
    dialogRef2: BsModalRef
    transactionItems: any;
    selectedItem: any;
    config: any[];
    eleIndex: string;

    private subject = new Subject<any>();
    childAttribute: any;
    addonchildd: any;

    constructor(
        private _db: AngularFireDatabase,
        private firedb: AngularFirestore,
        private _appService: AppService,
        private _router: Router,
        private _messagingService: MessagingService,
        private dialogService: BsModalService,
        private toaster: ToastrService,
        private toastr: ToastrService,
    ) {
        this.maxMiles = 3;
        this.customerModel = new CustomerModel({});
        this.allCustomers = [];
        this.allOrders = [];
        this.myOrder = new OrderModel({});
        this.myPastOrders = [];
        // this.orderCollectionRef = firedb.collection(COLLECTION_ORDER);
        this.onChangeMyOrder = new BehaviorSubject({});
        this.lastOrderId = 0;
        this.orderKey = '';
        this.lastOrderIds = [];
        this.lastOrderIds1 = [];
        this.allowOrder = 1;
        this.allowSunday = 0;
        this.allowMonday = 0;
        this.allowTuesday = 0;
        this.allowWednesday = 0;
        this.allowThursday = 0;
        this.allowFriday = 0;
        this.allowSaturday = 0;
        this.maxOrder = 100;
        this.orderCount = 0;
        this.eatMode = 'any';
        this.maxCollect_12 = 1;
        this.maxCollect_13 = 1;
        this.maxCollect_14 = 1;
        this.maxCollect_15 = 1;
        this.maxCollect_18 = 1;
        this.maxCollect_19 = 1;
        this.maxCollect_20 = 1;
        this.maxCollect_21 = 1;
        this.maxCollect_22 = 1;
        this.maxDel_12 = 1;
        this.maxDel_13 = 1;
        this.maxDel_14 = 1;
        this.maxDel_15 = 1;
        this.maxDel_18 = 1;
        this.maxDel_19 = 1;
        this.maxDel_20 = 1;
        this.maxDel_21 = 1;
        this.maxDel_22 = 1;

        this.transactionItems = {};
        this.transactionItems.items = [];
        this.transactionItems.total = 0;
        this.transactionItems.totalFormat = 0;

        // this._db.database.ref('/Configuration').once('value').then((snapshot) => {
        //     this.allowOrder = snapshot.val().allowOrder;
        //     this.maxOrder = snapshot.val().maxOrder;
        //     this.allowSunday = snapshot.val().allowSunday;
        //     this.allowMonday = snapshot.val().allowMonday;
        //     this.allowTuesday = snapshot.val().allowTuesday;
        //     this.allowWednesday = snapshot.val().allowWednesday;
        //     this.allowThursday = snapshot.val().allowThursday;
        //     this.allowFriday = snapshot.val().allowFriday;
        //     this.allowSaturday = snapshot.val().allowSaturday;

        //     this.maxCollect_12 = snapshot.val().maxCollect_12;
        //     this.maxCollect_13 = snapshot.val().maxCollect_13;
        //     this.maxCollect_14 = snapshot.val().maxCollect_14;
        //     this.maxCollect_15 = snapshot.val().maxCollect_15;
        //     this.maxCollect_18 = snapshot.val().maxCollect_18;
        //     this.maxCollect_19 = snapshot.val().maxCollect_19;
        //     this.maxCollect_20 = snapshot.val().maxCollect_20;
        //     this.maxCollect_21 = snapshot.val().maxCollect_21;
        //     this.maxCollect_22 = snapshot.val().maxCollect_22;
        //     this.maxDel_12 = snapshot.val().maxDel_12;
        //     this.maxDel_13 = snapshot.val().maxDel_13;
        //     this.maxDel_14 = snapshot.val().maxDel_14;
        //     this.maxDel_15 = snapshot.val().maxDel_15;
        //     this.maxDel_18 = snapshot.val().maxDel_18;
        //     this.maxDel_19 = snapshot.val().maxDel_19;
        //     this.maxDel_20 = snapshot.val().maxDel_20;
        //     this.maxDel_21 = snapshot.val().maxDel_21;
        //     this.maxDel_22 = snapshot.val().maxDel_22;
        //     this.maxMiles = snapshot.val().maxMiles;
        // });

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getMyPastOrders(): Promise<any> {
        const userId = this._appService.getCurUser().uid;
        return new Promise((resolve, reject) => {
            const db = this._db.database.ref();
            const eventRef = db.child(COLLECTION_ORDER);
            const query = eventRef.orderByChild('userId').equalTo(userId).limitToFirst(10);
            query.on('value', snap => {
                let orders = [];
                snap.forEach(function (childSnapshot) {
                    let temp = {
                        ...childSnapshot.val(),
                        cartItemList: childSnapshot.val().cartItemList.map(item => new OrderedFoodModel(item))
                    }
                    const item = new OrderModel(temp);
                    orders.push(item);
                });
                this.myPastOrders = orders.reverse();
                resolve(this.myPastOrders);
            }, reject)
        })
    }

    getOrderCount(date: string, time: string, OrderType: string): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log('date' + date);
            console.log('time' + time);
            let orders = [];
            this.firedb.collection(COLLECTION_ORDER, ref => ref.where('scheduledDate', '==', date).where('OrderType', '==', OrderType)).get()
                .subscribe(snap => {
                    var orderCount = 0;
                    snap.forEach(function (childSnapshot) {
                        if (childSnapshot.data().orderStatus != 3 && childSnapshot.data().orderStatus != 5 && childSnapshot.data().scheduledTime.indexOf(time) >= 0) {
                            orderCount++;
                        }
                    })
                    console.log('orderCount' + orderCount);
                    this.orderCount = orderCount;
                    resolve(this.orderCount);
                }, reject)
        })
    }

    checkAddons(addon1: AddonModel[], addon2: AddonModel[]): boolean {
        if (addon1.length == addon2.length) {
            for (let i = 0; i < addon1.length; i++) {
                if (JSON.stringify(addon1[i]) != JSON.stringify(addon2[i])) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    // checkDuplication(cartItem: OrderedFoodModel) {
    //     const idx = this.myOrder.cartItemList.findIndex(item => item.foodId == cartItem.foodId &&
    //         item.foodSize.name == cartItem.foodSize.name &&
    //         this.checkAddons(item.foodAddon, cartItem.foodAddon))

    //     if (idx > -1) {
    //         this.updateCartItem(idx, true);
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    // setCartItem(cartItem: OrderedFoodModel) {
    //     if (cartItem) {
    //         if (!this.checkDuplication(cartItem)) {
    //             this.myOrder.cartItemList.push(cartItem);
    //             this.myOrder.calcTotalPrice();
    //             this.onChangeMyOrder.next(this.myOrder);
    //         }
    //     }
    // }

    updateCartItem(index, operator) {
        if (operator) {
            this.myOrder.cartItemList[index].foodQuantity += 1;
        } else {
            this.myOrder.cartItemList[index].foodQuantity -= 1;
        }

        this.myOrder.calcTotalPrice();
        this.onChangeMyOrder.next(this.myOrder)
    }

    removeCartItem(cartItem: OrderedFoodModel, index) {
        if (cartItem) {
            this.myOrder.cartItemList.splice(index, 1);
            this.myOrder.calcTotalPrice();
            this.onChangeMyOrder.next(this.myOrder)
        }
    }

    applyCode(code: string) {
        // this.myOrder.discount = code
    }

    changePayment(transactionId: string) {
        this.myOrder.transactionId = transactionId;
    }
    getLastOrderId(): number {

        var thisObj = this;
        const this_db = this._db;
        const db = this._db.database.ref();
        const eventRef = db.child(COLLECTION_ORDER);
        const query = eventRef.orderByChild('orderId').limitToLast(1);
        var lastId = 0;
        query.on('value', snap => {
            snap.forEach(function (childSnapshot) {
                console.log(' childSnapshot key' + childSnapshot.key);
                this_db.database.ref('/Order/' + childSnapshot.key).once('value').then(function (snapshot) {
                    lastId = (snapshot.val() && snapshot.val().orderId) || 0;
                    lastId = lastId + 1;
                    return lastId;
                });
            });
        });
        return lastId;
    }
    // addCustomersFromOrder() {
    //     if (this.allOrders.length > 0) {
    //         for (var i = 300; i < 425; i++) {
    //             this.customerModel = new CustomerModel({});
    //             this.customerModel.address = this.allOrders[i].shippingAddress;
    //             this.customerModel.houseNumber = this.allOrders[i].houseNumber;
    //             this.customerModel.name = this.allOrders[i].userName;
    //             this.customerModel.phonenumber = this.allOrders[i].userPhone;
    //             this.customerModel.postcode = this.allOrders[i].postCode;
    //             this.customerModel.createDateTime = this.allOrders[i].createDate;
    //             if (!this.isCustomerExists()) {
    //                 let customerRef = this._db.database.ref("Customers");
    //                 var newCusRef = customerRef.push();
    //                 newCusRef.set(this.customerModel);
    //                 this.allCustomers.push(this.customerModel);
    //             }
    //         }
    //     }
    // }
    getAllOrders() {
        this.firedb.collection(COLLECTION_ORDER).get()
            .subscribe(snap => {
                var orderCount = 0;
                let allOrders = [];
                snap.forEach(function (childSnapshot) {
                    let temp = {
                        ...childSnapshot.data(),
                        cartItemList: childSnapshot.data().cartItemList.map(item => new OrderedFoodModel(item))
                    }
                    const item = new OrderModel(temp);
                    allOrders.push(item);
                })
                this.allOrders = allOrders;
            });
    }
    getAllCustomers(): Promise<any> {
        return new Promise((resolve, reject) => {
            const db = this._db.database.ref();
            const eventRef = this.firedb.collection('Customers').get()
            const query = eventRef;
            query.subscribe(snap => {
                let allCustomers = [];
                snap.docs.forEach(function (childSnapshot) {
                    let temp = {
                        ...childSnapshot.data()
                    }
                    const item = new CustomerModel(temp);
                    allCustomers.push(item);
                });
                this.allCustomers = allCustomers;
                resolve(this.allCustomers);
            }, reject)
        })
    }
    isCustomerExists(number) {
        if (this.allCustomers.length > 0) {
            for (var i = 0; i < this.allCustomers.length; i++) {
                let validNumber1 = this.allCustomers[i].phonenumber.substr(this.allCustomers[i].phonenumber.length - 10)
                let validNumber2 = number.substr(number.length - 10)
                if (validNumber1 == validNumber2) {
                    return true;
                }
            }
        }
    }
    addCustomer() {
        this.customerModel = new CustomerModel({});
        this.customerModel.address = this.myOrder.shippingAddress;
        //this.customerModel.houseNumber = this.myOrder.houseNumber;
        this.customerModel.name = this.myOrder.userName;
        this.customerModel.phonenumber = this.myOrder.userPhone;
        this.customerModel.postcode = this.myOrder.postCode;
        if (!this.isCustomerExists(this.myOrder.userPhone)) {
            let customerRef = this.firedb.collection('Customers').add(Object.assign({}, this.customerModel))
            // var newCusRef = customerRef.push();
            // newCusRef.set(this.customerModel);
        }
    }
    doOrder() {

        // this.myOrder.orderId = Math.floor(Math.random() * (999 - 100 + 1) + 100);//this.getLastOrderId();
        console.log(' this.myOrder.orderId' + this.myOrder.orderId);
        console.log(' this.lastOrderId' + JSON.stringify(this.myOrder));
        //this.myOrder.orderId = lastId;
        let key = UniqueIdGenerator.generate()
        this.myOrder.orderKey = key;
        this.myOrder.cartItemList = JSON.parse(JSON.stringify(this.myOrder.cartItemList)) || []
        this.myOrder.createDate = moment(new Date()).format('MM/DD/YYYY h:mm A');
        this.firedb.collection('Order').doc(key).set(Object.assign({}, this.myOrder))
        // this.orderCollectionRef.push(Object.assign({}, this.myOrder));

        // Order Message Sender
        if (CONSTANT.enableOrderSMS) this._messagingService.sendOrderSMS(this.myOrder);


        this.addCustomer();
        this.myOrder = new OrderModel({});
        this.onChangeMyOrder.next(this.myOrder);

        let message: string = '';
        if (this.myOrder.cod) {

            message = "You have a new order from " + this.myOrder.userName + " Phone: " + this.myOrder.userPhone
        } else {
            const today = moment(new Date()).format('MM/DD/YYYY h:mm A');
            message = "You have a new order from Phone: " + this.myOrder.userPhone + " at " + today
        }
        this._messagingService.sendMessage(message);

        return true;
    }

    getWebConfiguration(): Promise<any> {
        let _this = this;
        return new Promise((resolve, reject) => {
            var config = [];
            this.firedb.collection('Configuration').valueChanges()
                .subscribe((docs: any) => {
                    config = docs;
                    _this.config = config;
                    if (config['maxOrderAmount'] != undefined) {
                        _this.maxOrderAmount = config['maxOrderAmount'] || 100;
                    }

                    resolve(config)
                })
            return config;
        })
    }




    // getDayName(datestring) {
    //     var days = ['allowOrderSunday', 'allowOrderMonday', 'allowOrderTuesday', 'allowOrderWednesday', 'allowOrderThursday', 'allowOrderFriday', 'allowOrderSaturday'];
    //     var d = new Date(datestring);
    //     var dayName = days[d.getDay()];
    //     return dayName;
    // }
    // isDayAllowedOrder(day): Promise<any> {
    //     // var days = ['allowOrderSunday', 'allowOrderMonday', 'allowOrderTuesday', 'allowOrderWednesday', 'allowOrderThursday', 'allowOrderFriday', 'allowOrderSaturday'];
    //     return new Promise((resolve, reject) => {
    //         var d = new Date();
    //         var dayName = day;
    //         let allow = this.firedb.collection('Configuration').doc(dayName).get().subscribe(res => {
    //             var flag = 0;
    //             let enable = res.data().allow

    //             if (enable) {
    //                 flag = 1;
    //             }
    //             resolve(flag)
    //         })
    //     })

    // }

    checkWorkHour(time, scheduledate: any, formattedTime): any {
        var thisObj = this;
        const this_db = this._db;
        var allowOrder = 1;

        let _this = this;
        const now = new Date().getHours();
        const day = new Date().getDay();
        let scheduleTimes = [];
        let scheduleTimesArr = [];
        let isServing = true;

        const serviceTime = this.scheduleTimesList[day];
        const prevDayServiceTime = this.scheduleTimesList[day - 1];

        // if (scheduledate == '') {
        //     scheduledate = new Date().toLocaleDateString('en-GB', {
        //         month: 'short', day: '2-digit', year: 'numeric'
        //     }).replace(/ /g, ' ');

        // }



        if (time != '') {
            // time.forEach(currentTime => {
            //     this.getOrderCount(scheduledate, currentTime).then(function (orderCount) {
            //         (orderCount >= currentTime.totalOrder) ? currentTime.classList = 'option-strike' : currentTime.classList = ''
            //     });
            // })
            scheduleTimesArr = time.sort()
            scheduleTimes = time.sort()
            // this.getOrderCount(formattedDate, formattedTime).then(function (orderCount) {
            //     time.forEach(currentTime => {
            //         (currentTime.time == formattedTime) && (orderCount >= currentTime.totalOrder) ? currentTime.classList = 'option-strike' : currentTime.classList = 'option-strike'
            //     });
            // })
        }


        // _this.getWebConfiguration().then(function (settings) {
        //     settings.forEach(config => {
        //         if (config.ordertimes != undefined && config.ordertimes.length > 0) {
        //             let time = config.ordertimes.sort();

        //             // _this.selectedOrderTimes = []
        //             // _this.orderTimes.map(timeonly => {
        //             //     _this.selectedOrderTimes.push(timeonly.time)
        //             // })

        //         }
        //     });
        // })
        //console.log('day'+day);
        //console.log('now'+now);
        // console.log('prevDayServiceTime'+prevDayServiceTime.endTime.h);
        /*if(prevDayServiceTime.endTime.h <=5 && now < prevDayServiceTime.endTime.h){
            scheduleTimes.push("ASAP");
                isServing = true;
                if (now >= prevDayServiceTime.startTime.h) {
                    for(let i = now+1; i < 24; i++) {
                        scheduleTimes.push(this.getHString(i) + ':00');
                        scheduleTimes.push(this.getHString(i) + ':30');
                    }

                    for(let j = 0; j < prevDayServiceTime.endTime.h; j++) {
                        scheduleTimes.push(this.getHString(j) + ':00');
                        scheduleTimes.push(this.getHString(j) + ':30');
                    }
                } else {
                    for(let k = now+1; k < prevDayServiceTime.endTime.h; k++) {
                        scheduleTimes.push(this.getHString(k) + ':00');
                        scheduleTimes.push(this.getHString(k) + ':30');
                    }
                }
        }
        else {*/
        /*if ((now >= serviceTime.endTime.h || now < serviceTime.startTime.h) && (now >= serviceTime.endTime1.h || now < serviceTime.startTime1.h)) {
            scheduleTimes.push('Timeout');
            isServing = false;
        } else {*/
        //scheduleTimes.push("ASAP");
        isServing = true;
        //console.log('this.eatModeorder'+this.eatMode);

        /* if (this.eatMode != 'home') {
             if (this.maxCollect_13 > 0) {
                 let scheduleTime = { 'time': '13:00', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
             }
             else {
                 let scheduleTime = { 'time': '13:00', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
             }
 
             if (this.maxCollect_14 > 0) {
                 let scheduleTime = { 'time': '14:00', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
                 let scheduleTime1 = { 'time': '14:30', 'class': '' }
                 scheduleTimesArr.push(scheduleTime1);
             }
             else {
                 let scheduleTime = { 'time': '14:00', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
                 let scheduleTime1 = { 'time': '14:30', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime1);
             }
 
             if (this.maxCollect_18 > 0) {
                 let scheduleTime = { 'time': '18:30', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
             }
             else {
                 let scheduleTime = { 'time': '18:30', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
             }
 
             if (this.maxCollect_19 > 0) {
                 let scheduleTime = { 'time': '19:30', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
             }
             else {
                 let scheduleTime = { 'time': '19:30', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
             }
 
             if (this.maxCollect_20 > 0) {
                 let scheduleTime = { 'time': '20:30', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
             }
             else {
                 let scheduleTime = { 'time': '20:30', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
             }
 
             if (this.maxCollect_21 > 0) {
                 let scheduleTime = { 'time': '21:30', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
             }
             else {
                 let scheduleTime = { 'time': '21:30', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
             }
            
         }
         else {
 
             if (this.maxDel_19 > 0) {
                 let scheduleTime = { 'time': '19:00', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
             }
             else {
                 let scheduleTime = { 'time': '19:00', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
             }
 
             if (this.maxDel_20 > 0) {
                 let scheduleTime = { 'time': '20:00', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
             }
             else {
                 let scheduleTime = { 'time': '20:00', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
             }
 
             if (this.maxDel_21 > 0) {
                 let scheduleTime = { 'time': '21:00', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
             }
             else {
                 let scheduleTime = { 'time': '21:00', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
             }
 
             if (this.maxDel_22 > 0) {
                 let scheduleTime = { 'time': '22:00', 'class': '' }
                 scheduleTimesArr.push(scheduleTime);
             }
             else {
                 let scheduleTime = { 'time': '22:00', 'class': 'option-strike' }
                 scheduleTimesArr.push(scheduleTime);
             }
         }*/
        /*if(this.eatMode != 'home'){
            for(let i = 12; i <= 15; i++) {
                scheduleTimes.push(this.getHString(i) + ':00');

                if(i != 15)
                scheduleTimes.push(this.getHString(i) + ':30');
            }
        }
        for(let i = 18; i <= 22; i++) {
            scheduleTimes.push(this.getHString(i) + ':00');

            if(i != 22)
            scheduleTimes.push(this.getHString(i) + ':30');
        }*/
        /*if (now >= serviceTime.startTime.h) {
            for(let i = now+1; i < 24; i++) {
                scheduleTimes.push(this.getHString(i) + ':00');
                scheduleTimes.push(this.getHString(i) + ':30');
            }

            for(let j = 0; j < serviceTime.endTime.h; j++) {
                scheduleTimes.push(this.getHString(j) + ':00');
                scheduleTimes.push(this.getHString(j) + ':30');
            }
        } else {
            for(let k = now+1; k < serviceTime.endTime.h; k++) {
                scheduleTimes.push(this.getHString(k) + ':00');
                scheduleTimes.push(this.getHString(k) + ':30');
            }
        }*/

        //}
        // }
        //console.log('scheduleTimes'+scheduleTimes);
        //console.log('scheduleTimes'+JSON.stringify(scheduleTimes));    
        return { times: scheduleTimes, timesArr: scheduleTimesArr, isServing: isServing };
    }

    getHString(h) {
        return h < 10 ? '0' + h.toString() : h.toString();
    }

    getMString(m) {
        return m === 0 ? '00' : '30';
    }

    getTodayWorkTime() {
        const day = new Date().getDay();
        const serviceTime = this.scheduleTimesList[day];
        if (serviceTime.startTime.h == 0 && serviceTime.startTime.m == 0 && serviceTime.endTime.h == 0 && serviceTime.endTime.m == 0) {
            return 'Shop is closed';
        }
        else {
            return this.getHString(serviceTime.startTime.h) + ":" + this.getMString(serviceTime.startTime.m) + " - " +
                this.getHString(serviceTime.endTime.h) + ":" + this.getMString(serviceTime.endTime.m) + ' & ' + this.getHString(serviceTime.startTime1.h) + ":" + this.getMString(serviceTime.startTime1.m) + " - " +
                this.getHString(serviceTime.endTime1.h) + ":" + this.getMString(serviceTime.endTime1.m);
        }
    }


    //     closeRef() {
    //     this.dialogRef.hide()
    // }
    // New Functions

    filterResultsFromFirestore(id) {
        let data = []
        return new Promise((resolve, reject) => {
            this.firedb.collection('Foods', ref => ref.where('categoryId', '==', id)).get()
                .subscribe(snapshot => {
                    snapshot.docs.forEach(event => {
                        if (event.data()['image'] == '') {
                            event.data()['image'] = CONSTANT.logo
                        }
                        data.push(event.data())
                    })
                    resolve(data)
                })
        })
    }

    public getItemNew(item_id, transactionItems, items): Promise<any> {
        return new Promise((resolve, reject) => {
            var exists = 0;
            for (var i = 0; i < (transactionItems.items).length; i++) {
                if ((transactionItems.items[i]).item.id == item_id) {
                    exists = i + 1;
                }
            }

            items.forEach(item => {
                if (item.id == item_id) {
                    this.selectedItem = {}
                    this.selectedItem = item;

                }
            });
            if (exists == 0) {
                this.selectedItem.qty = 1;
                this.selectedItem.discount = 0;
                this.selectedItem.totalPrice = this.selectedItem.qty * this.selectedItem.price;
                this.selectedItem.totalPriceFormat = this.selectedItem.totalPrice.toFixed(2);
                this.selectedItem.priceFormat = parseFloat(this.selectedItem.price).toFixed(2);
            }
            else {
                this.selectedItem.qty = (transactionItems.items[exists - 1]).item.qty;
                this.selectedItem.discount = (transactionItems.items[exists - 1]).item.discount;
                this.selectedItem.totalPrice = this.selectedItem.qty * this.selectedItem.price;
                this.selectedItem.totalPriceFormat = this.selectedItem.totalPrice.toFixed(2);
                this.selectedItem.priceFormat = parseFloat(this.selectedItem.price).toFixed(2);
            }
            resolve(this.selectedItem)
        })
    }

    showAddonsInPopupNew(showcart, item, show, FoodItems, transactionAddons, transactionSize, transactionItems, selectedItem, orderDiscount, isRequired, index, index2): Promise<any> {
        console.log(FoodItems[1])
        return new Promise((resolve, reject) => {
            var _this = this;
            // this.getItem(item.id);
            let html = []
            FoodItems.map(data => {
                if (data.id == item.id) {
                    let array = data;
                    console.log("event: " + JSON.stringify(data));
                    if (array['Attributes'] != undefined) {
                        for (var i = 0; i < array['Attributes'].length; i++) {
                            array['Attributes'][i]['error'] = ''
                            var att = array['Attributes'][i]
                            // if (array['Attributes'][i]['isRequired']) {
                            //     isRequired.push(array['Attributes'][i]['title'])
                            // }
                            html.push(att)

                        }
                    }

                    let claFlag = 1;

                    let cls = 'showadd_' + index + index2;
                    let element = document.getElementsByClassName(cls)
                    if (element[0] != undefined && element[0] != null && element[0].classList.contains('show')) {
                        element[0].classList.remove('show')
                        claFlag = 0;
                    }
                    if (element[1] != undefined && element[1] != null && element[1].classList.contains('show')) {
                        element[1].classList.remove('show')
                        claFlag = 0;
                    }

                    if (claFlag == 1) {
                        let ele = document.getElementsByClassName('toggleShow')
                        for (let x = 0; x < ele.length; x++) {
                            if (ele[x].classList.contains('show'))
                                ele[x].classList.toggle('show')
                        }
                    }



                    if (html.length == 0 && (selectedItem.size == undefined || selectedItem.size.length == 0)) {
                        // _this.addItemToTransaction(item.id, 0);
                        _this.addItemToTransaction(showcart, item.id, show, FoodItems, transactionAddons, transactionSize, transactionItems, selectedItem, orderDiscount, isRequired, html, index).then(items => {

                            this.transactionItems = items
                        })
                    }
                    else {

                        // this.dialogRef = this.dialogService.show(popup, {
                        //     class: 'addons_popup'
                        // })
                        if (claFlag == 1) {
                            let cls = 'showadd_' + index + index2;
                            let element = document.getElementsByClassName(cls)
                            element[0].classList.toggle('show')
                            element[1].classList.toggle('show')
                        }
                        let newObj = {
                            html: html,
                            size: selectedItem.size || [],
                            items: this.transactionItems,
                            selectedItem: selectedItem
                        }
                        resolve(newObj)
                    }
                }
            })
        })
    }

    addItemToTransaction(showcart, item_id, show, FoodItems, transactionAddons, transactionSize, transactionItems, selectedItem, orderDiscount, isRequired, html, childAttribute): Promise<any> {
        return new Promise((resolve, reject) => {
            var ls = new secureLS({ encodingType: 'aes' });
            var _this = this;
            // _this.getOrderId()
            var flag = 1;
            let limit = 1;
            if (item_id != '')
                this.getItemNew(item_id, transactionItems, FoodItems);


            /************** Check If Addon Object Null ****************/

            for (let x in transactionAddons) {

                if (transactionAddons[x]['subaddons'] != undefined && transactionAddons[x]['subaddons'].length > 0) {
                    for (let y in transactionAddons[x]['subaddons']) {
                        if (transactionAddons[x]['subaddons'][y].obj.length == 0) {
                            transactionAddons[x]['subaddons'].splice(y, 1)
                        }
                    }
                }
                if (transactionAddons[x]['subaddons'] != undefined && transactionAddons[x]['subaddons'].length == 0) {
                    transactionAddons.splice(x, 1)
                }
                if (transactionAddons[x]['obj'] != undefined && transactionAddons[x]['obj'].length == 0) {
                    transactionAddons.splice(x, 1)
                }
            }

            /************** Check If Addon Object Null End ****************/

            /************** Check Item Addon required ****************/

            if (selectedItem.size != undefined && selectedItem.size.length > 0 && transactionSize.length == 0) {
                this.toaster.warning(`Please Select Any 1 Size`)
                flag = 0;
                process.exit()
            }
            else if (selectedItem.Attributes.length > 0) {
                let itemAttribute = selectedItem.Attributes;
                itemAttribute.forEach(ItemElement => {
                    let checkrequired = 1;
                    limit = 1;
                    if (ItemElement.required || ItemElement.isRequired) {
                        transactionAddons.forEach(transactionElement => {
                            if (transactionElement.title == ItemElement.title) {
                                checkrequired = 0;
                                if (this.checkChildAttributeRequirements(this.addonchildd, transactionAddons, childAttribute)) {
                                    flag = 0;
                                }
                                else {
                                    flag = 1;
                                }

                            }
                        });
                        if (ItemElement.limit != undefined) {
                            limit = ItemElement.limit;
                        }
                        if (checkrequired == 1) {
                            this.toaster.warning(`Please Select Any ${limit} ${ItemElement.title}`)
                            flag = 0;
                            process.exit()
                        }
                    }
                    else if (ItemElement.FoodExtras.length > 0 && ItemElement.title != 'DEALOPTION') {
                        let foodExtra = ItemElement.FoodExtras
                        foodExtra.forEach(extraItem => {
                            if (extraItem.htmlfordeal != undefined && extraItem.htmlfordeal.length > 0) {
                                extraItem.htmlfordeal.forEach(subExtraItem => {
                                    let checkSubRequire = 1;
                                    limit = 1;
                                    if (subExtraItem.isRequired) {
                                        transactionAddons.forEach(transactionElement => {
                                            if (transactionElement.subaddons != undefined && transactionElement.subaddons.length > 0) {
                                                transactionElement.subaddons.forEach(tranSubAddon => {
                                                    if (tranSubAddon.name == subExtraItem.title) {
                                                        if (subExtraItem.limit != undefined) {
                                                            if (tranSubAddon.obj.length == subExtraItem.limit) {
                                                                checkSubRequire = 0;
                                                            }
                                                            else {
                                                                limit = subExtraItem.limit
                                                            }

                                                        }
                                                        else if (subExtraItem.limit == undefined) {
                                                            checkSubRequire = 0;
                                                        }
                                                    }
                                                    else if (subExtraItem.limit != undefined) {
                                                        limit = subExtraItem.limit
                                                    }
                                                });
                                            }
                                        });

                                    }
                                    if (checkSubRequire == 1 && subExtraItem.isRequired == true) {
                                        this.toaster.warning(`Please Select Any ${limit} ${subExtraItem.title}`)
                                        flag = 0;
                                        process.exit()
                                    }
                                });
                            }
                        })
                    }
                });
            }

            // let uniqueArray = Array.from(new Set(isRequired));
            // for (let y in uniqueArray) {
            //     var reqiuredFlag = 1;
            //     for (let x in transactionAddons) {
            //         if (uniqueArray[y] == transactionAddons[x].title) {
            //             flag = 1;
            //             reqiuredFlag = 0;
            //         }
            //     }
            //     if (transactionAddons.length == 0 && html.length > 0) {
            //         this.toaster.warning(`Please Select Any One ${uniqueArray[y]}`)
            //         flag = 0;
            //     }
            //     else if (reqiuredFlag == 1 && html.length > 0) {
            //         this.toaster.warning(`Please Select Any One ${uniqueArray[y]}`)
            //         flag = 0;
            //     }
            // }
            /************** Check Item Addon required End ****************/




            if (flag == 1) {
                var exists = 0;
                var itemExists = 0;
                exists = this.isItemExistsInTransaction(transactionItems, transactionAddons, transactionSize, selectedItem)
                // exists = this.isItemExistsInTransaction2(selectedItem);
                if (exists > 0) {
                    var selItem = transactionItems.items[exists - 1].item;
                    var qty = selItem.qty;
                    var totalPrice = selItem.totalPrice;
                    qty = qty + 1;
                    totalPrice = totalPrice * qty;
                    selItem.qty = qty;
                    selItem.totalPrice = totalPrice;
                    transactionItems.items[exists - 1].item = selItem;
                }
                else {
                    var transactionItem = {};
                    if (itemExists > 0) {
                        // selectedItem.diff = (itemExists - 1);
                        // transactionItem = { 'item': selectedItem, 'addons': transactionAddons, 'salads': this.transactionSalads, 'sauces': this.transactionSauces, 'drinks': this.transactionDrinks, 'sizes': this.transactionSizes, 'diff': 1 };
                        selectedItem.diff = (itemExists - 1);
                        transactionItem = { 'item': this.selectedItem, 'addons': transactionAddons, 'sizes': transactionSize, 'diff': 1 };
                    }
                    else {
                        // transactionItem = { 'item': selectedItem, 'addons': transactionAddons, 'salads': this.transactionSalads, 'sauces': this.transactionSauces, 'drinks': this.transactionDrinks, 'sizes': this.transactionSizes };
                        transactionItem = { 'item': this.selectedItem, 'addons': transactionAddons, 'sizes': transactionSize, };
                    }
                    transactionItems['items'].push(transactionItem);
                }
                // var ele = document.getElementsByClassName('addon_select');
                // for (var i = 0; i < ele.length; i++) {
                //     var child = ele[i];
                //     child.classList.remove('addon_select');
                // }
                let ele = document.getElementsByClassName('toggleShow')
                for (let x = 0; x < ele.length; x++) {
                    if (ele[x].classList.contains('show'))
                        ele[x].classList.toggle('show')
                }



                let checkBox = document.querySelectorAll('input[type="checkbox"]');
                for (let x = 0; x < checkBox.length; x++) {
                    let element = checkBox[x] as HTMLInputElement
                    if (element.checked)
                        element.checked = false
                }

                // this.updateTransactionPricesNew();
                this.updateTransactionPrices(showcart, transactionItems)
                // if (transactionAddons.length > 1) {
                // >>>>>> this.showItemsAndCategories(transactionItems);
                // }
                // this.getCategories().then(function (list) {
                //      return list;
                // })
                // } 
                // let foodItems = ls.get('vederohsik').data;
                // if (foodItems != undefined && foodItems != null) {
                //     foodItems.items.map(event => {
                //         transactionItems['items'].push(event)
                //     })
                // }
                ls.set('vederohsik', { data: transactionItems });


                transactionAddons = []
                isRequired = []
                if (this.dialogRef != undefined) {
                    this.dialogRef.hide()
                }

                var element = document.getElementsByClassName('fixedHeight')[0]
                element.scrollTo(0, (element.clientHeight + 50))
                // document.getElementById('cart_count').innerHTML = transactionItems.noitems;

                // $('#message').toast("show")
                this.toastr.success("Item added to cart")
                resolve(transactionItems)
            }
            reject
        });

    }

    returnTransactionItems() {
        return this.subject.asObservable()
    }

    isItemExistsInTransaction(transactionItems, transactionAddons, transactionSize, item) {
        for (var i = 0; i < (transactionItems.items).length; i++) {
            if ((transactionItems.items[i]).item.id == item.id) {
                if (JSON.stringify(transactionItems.items[i].addons) == JSON.stringify(transactionAddons) && JSON.stringify(transactionItems.items[i].sizes) == JSON.stringify(transactionSize)) {
                    return i + 1;
                }
            }
        }
        return 0;
    }


    updateTransactionPrices(showcart, transactionItemValues) {
        return new Promise((resolve, reject) => {
            var noitems = 0;
            var total = 0;
            // var due = 0;
            // var item_discount = 0;
            var item_total_discount = 0;
            transactionItemValues.items.forEach(curItem => {

                let currentItem = curItem.item
                // No Of Items
                noitems += currentItem.qty;

                // Discount
                // if (typeof (currentItem.discount) != 'undefined') {
                //     item_discount = parseFloat(currentItem.discount);
                //     item_total_discount += parseFloat(currentItem.discount);
                // }

                // Total Price ( Qty x ( Price - Discount ))
                currentItem.totalPrice = currentItem.qty * (parseFloat(currentItem.price));

                // Total
                total += parseFloat(currentItem.totalPrice);

                // Due
                // due += parseFloat(currentItem.totalPrice);


                /******************************* ADDON PRICE CALCULATION *************************************/

                // Calculate Size Price
                if (curItem.sizes != undefined)
                    curItem.sizes.forEach(size => {
                        currentItem.totalPrice += currentItem.qty * parseFloat((size).price);
                        total += currentItem.qty * parseFloat((size).price);
                    })

                // Calculate Addon Price
                curItem.addons.forEach(addon => {
                    if (addon.obj != undefined) {
                        addon.obj.forEach(addonobj => {
                            currentItem.totalPrice += currentItem.qty * parseFloat((addonobj).price);
                            total += currentItem.qty * parseFloat((addonobj).price);
                            // due += currentItem.qty * parseFloat((addonobj).price);

                            // Calculate Addon Maintoppings Price
                            addonobj.maintoppings.forEach(maintoppings => {
                                currentItem.totalPrice += currentItem.qty * parseFloat((maintoppings).price);
                                total += currentItem.qty * parseFloat((maintoppings).price);
                                // due += currentItem.qty * parseFloat((maintoppings).price);

                                if (maintoppings != undefined && maintoppings.subtoppings.length > 0) {
                                    maintoppings.subtoppings.forEach(subtoppings => {
                                        currentItem.totalPrice += currentItem.qty * parseFloat((subtoppings).price);
                                        total += currentItem.qty * parseFloat((subtoppings).price);
                                        // due += currentItem.qty * parseFloat((subtoppings).price);


                                    });
                                }
                            });
                        });
                    }
                    else {
                        // Calculate SubAddon Price ( Deal Option )
                        addon.subaddons.forEach(subaddon => {
                            total += parseFloat((subaddon).price) || 0;
                            // due += parseFloat((subaddon).price) || 0;
                            currentItem.totalPrice += parseFloat((subaddon).price);
                            subaddon.obj.forEach(addonobj => {
                                currentItem.totalPrice += currentItem.qty * parseFloat((addonobj).price);
                                total += currentItem.qty * parseFloat((addonobj).price);
                                // due += currentItem.qty * parseFloat((addonobj).price);

                                // Calculate SubAddon Maintoppings Price ( Deal Option )
                                addonobj.maintoppings.forEach(maintoppings => {
                                    currentItem.totalPrice += currentItem.qty * parseFloat((maintoppings).price);
                                    total += currentItem.qty * parseFloat((maintoppings).price);
                                    // due += currentItem.qty * parseFloat((maintoppings).price);

                                    if (maintoppings != undefined && maintoppings.subtoppings.length > 0) {
                                        maintoppings.subtoppings.forEach(subtoppings => {
                                            currentItem.totalPrice += currentItem.qty * parseFloat((subtoppings).price);
                                            total += currentItem.qty * parseFloat((subtoppings).price);
                                            // due += currentItem.qty * parseFloat((subtoppings).price);


                                        });
                                    }
                                });
                            });
                        });
                    }
                });
                /******************************* END ADDON PRICE CALCULATION *************************************/

                // Item Total Price 
                // currentItem.price = currentItem.totalPrice.toFixed(2);
                // currentItem.priceFormat = currentItem.totalPrice.toFixed(2);
                currentItem.totalPriceFormat = currentItem.totalPrice.toFixed(2);
                currentItem = ''
            });
            let itemTotal = total

            transactionItemValues.noitems = noitems;
            transactionItemValues.total = itemTotal;
            transactionItemValues.totalFormat = itemTotal.toFixed(2);

            if (showcart)
                this.subject.next(transactionItemValues)
            // transactionItemValues.due = 0;
            // transactionItemValues.dueFormat = transactionItemValues.due.toFixed(2);
            // transactionItemValues.discountFormat = parseFloat(transactionItemValues.discount).toFixed(2)
            resolve(transactionItemValues)
        })
    }

    addAddonToTransaction(name, price, type, $event, required, limit, item, transactionAddons, isRequired, addonchildd, childAttribute) {
        return new Promise((resolve, reject) => {
            let addonlimit = 0;
            let addonobj = { "name": name, "price": price, "type": type, 'maintoppings': [] }
            var ele = $event.target;
            transactionAddons.map(addon => {
                if (addon.title == type) {
                    addonlimit = addon['obj'].length
                }
            })
            if (!ele.checked) {
                for (var i = 0; i < transactionAddons.length; i++) {
                    for (let x in transactionAddons) {
                        if (transactionAddons[x].title == type) {
                            for (let y in transactionAddons[x]['obj']) {
                                if (transactionAddons[x]['obj'][y].name == name) {
                                    transactionAddons[x]['obj'].splice(y, 1)
                                    item['error'] = '';
                                    // if (required) {
                                    //     isRequired.push(type)
                                    // }
                                }
                            }
                        }
                    }
                }
            }
            else {
                if (addonlimit != Number(limit)) {
                    var flag = 1;
                    var obj = { "title": type, obj: [addonobj] }
                    for (let x in transactionAddons) {
                        if (transactionAddons[x].title == type) {
                            transactionAddons[x]['obj'].push(addonobj)
                            flag = 0;
                        }
                    }
                    if (flag == 1) { transactionAddons.push(obj) }
                    if (item.FoodExtras != undefined) {
                        for (let m in item.FoodExtras) {
                            var attribute = item.FoodExtras[m]['childAttribute'];
                            if (item.FoodExtras[m]['childPopup']) {
                                if (attribute != undefined && (item.FoodExtras[m].name == name) && attribute.length > 0) {
                                    addonchildd = attribute;
                                    childAttribute = item.FoodExtras[m]
                                    // this.dialogRef2 = this.dialogService.show(template, {
                                    //     class: 'addons_popup'
                                    // })
                                }
                            }
                        }
                    }
                    // if (required) {
                    //     for (var j = 0; j < isRequired.length; j++) {
                    //         if (isRequired[j] == type) {
                    //             isRequired.splice(j, 1)
                    //         }
                    //     }
                    // }
                }
                else {
                    this.toaster.warning(`Select Any ${limit} ${type} Only!`)
                    ele.checked = false
                }
            }
            this.childAttribute = childAttribute;
            this.addonchildd = addonchildd;
            let objnew = {
                childAttribute: childAttribute,
                addonchild: addonchildd
            }
            resolve(objnew)
        })
    }

    addAddonChildToTransaction(name, price, color, type, event, required, limit, item, template, transactionAddons, isRequired, childAttribute) {
        item['error'] = '';
        let addonlimit = 0;
        let addonobj = { "name": name, "price": price, "type": type, "color": color, subtoppings: [] }
        var ele = event.target;
        transactionAddons.map(addon => {
            if (addon['obj'] != undefined) {
                addon.obj.map(childAddon => {
                    if (childAddon.maintoppings != undefined) {
                        addonlimit = childAddon.maintoppings.length
                    }
                })
            }
            else {
                addon.subaddons.map(childAddon => {
                    childAddon.obj.map(childAddonobj => {
                        childAddonobj.maintoppings.map(childAddonmt => {
                            if (childAddonmt.type == type) {
                                addonlimit++
                            }
                        })
                    })
                })
            }

        })
        if (!event.target.checked) {
            transactionAddons.map(addonparent => {
                if (addonparent['obj'] != undefined) {
                    addonparent.obj.map(addon => {
                        for (var i = 0; i < addon['maintoppings'].length; i++) {
                            if ((addon['maintoppings'][i].name == name) && (addon['maintoppings'][i].type == type)) {
                                addonlimit++
                                addon['maintoppings'].splice(i, 1)
                                item['error'] = '';
                                // if (required) {
                                //     isRequired.push(type)
                                // }
                            }
                        }
                    })
                }
                else {
                    addonparent.subaddons.map(addonobj => {
                        addonobj.obj.map(addon => {
                            for (var i = 0; i < addon['maintoppings'].length; i++) {
                                if ((addon['maintoppings'][i].name == name) && (addon['maintoppings'][i].type == type)) {
                                    addonlimit++
                                    addon['maintoppings'].splice(i, 1)
                                    item['error'] = '';
                                    // if (required) {
                                    //     isRequired.push(type)
                                    // }
                                }
                            }
                        })
                    })
                }
            })
        }
        else {
            let flag = 1;
            if (addonlimit != Number(limit)) {
                // if (ele.parentNode.classList.contains('addonBtn')) {
                transactionAddons.map(addonparent => {
                    if (addonparent['obj'] != undefined) {
                        addonparent.obj.map(addonchild => {
                            if (addonchild.name == childAttribute.name) {
                                addonparent.obj.map(addon => {
                                    if (flag == 1) {
                                        addon['maintoppings'].push(addonobj)
                                        addon['title'] = type;
                                        flag = 0;
                                        // ele.parentNode.style = `background:${color};color:white;`
                                    }
                                })
                            }
                        })
                    }
                    else {
                        addonparent.subaddons.map(addonchildobj => {
                            addonchildobj.obj.map(addonchild => {
                                if (addonchild.name == childAttribute.name) {
                                    addonparent.subaddons.map(addons => {
                                        addons.obj.map(addon => {
                                            if (flag == 1) {
                                                addon['maintoppings'].push(addonobj)
                                                addon['title'] = type;
                                                flag = 0;
                                                // ele.parentNode.style = `background:${color};color:white;`
                                            }
                                        })
                                    })
                                }
                            })
                        })
                    }

                })
                // if (required) {
                //     for (var j = 0; j < isRequired.length; j++) {
                //         if (isRequired[j] == type) {
                //             isRequired.splice(j, 1)
                //         }
                //     }
                // }
                //  }
            }
            else {
                if (limit != 'nolimit') {
                    this.toaster.warning(`Select Any ${limit} ${type} Only!`)
                    ele.checked = false
                }
            }
        }
    }

    addAddonChildToTransaction_2(name, price, color, type, $event, required, limit, item, template, transactionAddons, isRequired, childAttribute, maintoppingname) {
        item['error'] = '';
        let addonlimit = 0;
        let addonobj = { "name": name, "price": price, "type": type, "color": color }
        if (item['addontype'] != undefined && item['addontype'] == 'Remove') { color = 'red'; addonobj.price = 0 }
        var ele = $event.target;
        transactionAddons.map(addon => {
            if (addon['obj'] != undefined) {
                addon.obj.map(childAddon => {
                    if (childAddon.maintoppings != undefined) {
                        childAddon.maintoppings.forEach(maintopping => {
                            if (maintopping.type == childAttribute.child) {
                                addonlimit = maintopping.subtoppings.length
                            }
                        });
                    }
                })
            }
            else {
                addon.subaddons.map(childAddon => {
                    childAddon.obj.map(childAddonobj => {
                        childAddonobj.maintoppings.map(childAddonmt => {
                            if (childAddonmt.type == childAttribute.child) {
                                childAddonmt.subtoppings.forEach(subtopping => {
                                    if (subtopping.type == type) {
                                        addonlimit++
                                    }
                                });
                            }
                            // if (childAddonmt.subtoppings == type) {
                            //     addonlimit++
                            // }
                        })
                    })
                })
            }

        })
        if (!ele.checked) {
            transactionAddons.map(addonparent => {
                if (addonparent['obj'] != undefined) {
                    addonparent.obj.map(addon => {
                        for (var i = 0; i < addon['maintoppings'].length; i++) {
                            if ((addon['maintoppings'][i].name == maintoppingname) && (addon['maintoppings'][i].type == childAttribute.child)) {
                                // addon['maintoppings'][i]['subtoppings'].push(addonobj)
                                for (let x = 0; x < addon['maintoppings'][i]['subtoppings'].length; x++) {
                                    if (addon['maintoppings'][i]['subtoppings'][x].name == name) {
                                        addon['maintoppings'][i]['subtoppings'].splice(x, 1)
                                        item['error'] = '';
                                    }
                                }
                                // addon['maintoppings'][i]['title'] = type
                                // ele.parentNode.style = `background:${color};color:white;`
                            }
                        }
                        // for (var i = 0; i < addon['maintoppings'].length; i++) {
                        //     if ((addon['maintoppings'][i].name == name) && (addon['maintoppings'][i].type == type)) {
                        //         addonlimit++
                        //         addon['maintoppings'].splice(i, 1)
                        //         item['error'] = '';
                        //         // if (required) {
                        //         //     isRequired.push(type)
                        //         // }
                        //     }
                        // }
                    })
                }
                else {
                    addonparent.subaddons.map(addonobj => {
                        addonobj.obj.map(addon => {
                            for (var i = 0; i < addon['maintoppings'].length; i++) {
                                if ((addon['maintoppings'][i].name == maintoppingname) && (addon['maintoppings'][i].type == childAttribute.child)) {
                                    // addon['maintoppings'][i]['subtoppings'].push(addonobj)
                                    for (let x = 0; x < addon['maintoppings'][i]['subtoppings'].length; x++) {
                                        if (addon['maintoppings'][i]['subtoppings'][x].name == name) {
                                            addon['maintoppings'][i]['subtoppings'].splice(x, 1)
                                            item['error'] = '';
                                        }
                                    }
                                    // addon['maintoppings'][i]['title'] = type
                                    // ele.parentNode.style = `background:${color};color:white;`
                                }
                            }
                            // for (var i = 0; i < addon['maintoppings'].length; i++) {
                            //     if ((addon['maintoppings'][i].name == name) && (addon['maintoppings'][i].type == type)) {
                            //         addonlimit++
                            //         addon['maintoppings'].splice(i, 1)
                            //         item['error'] = '';
                            //         // if (required) {
                            //         //     isRequired.push(type)
                            //         // }
                            //     }
                            // }
                        })
                    })
                }
            })
        }
        else {
            let flag = 1;
            if (addonlimit != Number(limit)) {
                // if (ele.parentNode.classList.contains('addonbuttonProduct')) {
                transactionAddons.map(addonparent => {
                    if (addonparent['obj'] != undefined) {
                        addonparent.obj.map(addonchild => {
                            if (addonchild.name == childAttribute.name) {
                                // addonparent.obj.map(addon => {
                                if (flag == 1) {
                                    for (var i = 0; i < addonchild['maintoppings'].length; i++) {
                                        if ((addonchild['maintoppings'][i].name == maintoppingname) && (addonchild['maintoppings'][i].type == childAttribute.child)) {
                                            addonchild['maintoppings'][i]['subtoppings'].push(addonobj)
                                            addonchild['maintoppings'][i]['title'] = type
                                            flag = 0;
                                            // ele.parentNode.style = `background:${color};color:white;`
                                        }
                                    }
                                    // addonchild['maintoppings'].push(addonobj)
                                    // addonchild['title2'] = type;
                                    // flag = 0;
                                    // ele.parentNode.style = `background:${color};color:white;`
                                }
                                // })
                                // if (item.FoodExtras != undefined) {
                                //     for (let m in item.FoodExtras) {
                                //         var attribute = item.FoodExtras[m]['childAttribute'];
                                //         if (item.FoodExtras[m]['childPopup']) {
                                //             if (attribute != undefined && (item.FoodExtras[m].name == name) && attribute.length > 0) {
                                //                 childAttribute = item.FoodExtras[m]
                                //                 // this.dialogRef1 = this.dialogService.open(template, {
                                //                 //     context: childAttribute,
                                //                 //     closeOnBackdropClick: false,
                                //                 //     dialogClass: 'childAddon'
                                //                 // })
                                //             }
                                //         }
                                //     }
                                // }
                            }
                        })
                    }
                    else {
                        addonparent.subaddons.map(addonchildobj => {
                            addonchildobj.obj.map(addonchild => {
                                if (addonchild.name == childAttribute.name) {
                                    // addonparent.subaddons.map(addons => {
                                    //     addons.obj.map(addon => {
                                    if (flag == 1) {
                                        for (var i = 0; i < addonchild['maintoppings'].length; i++) {
                                            if ((addonchild['maintoppings'][i].name == maintoppingname) && (addonchild['maintoppings'][i].type == childAttribute.child)) {
                                                addonchild['maintoppings'][i]['subtoppings'].push(addonobj)
                                                addonchild['maintoppings'][i]['title'] = type
                                                flag = 0;
                                                // ele.parentNode.style = `background:${color};color:white;`
                                            }
                                        }
                                        // addonchild['maintoppings'].push(addonobj)
                                        // addonchild['title2'] = type;
                                        // flag = 0;
                                        // ele.parentNode.style = `background:${color};color:white;`
                                    }
                                    //     })
                                    // })
                                }
                            })
                        })
                    }

                })
                // }
            }
            else {
                if (limit != 'nolimit') {
                    item['error'] = `Select Any ${limit} ${type} Only!`
                }
            }
        }
    }


    addAddonDealOptionToTransaction(name, price, color, type, $event, required, limit, item, template, idx, transactionAddons, isRequired, childAttribute, addonchild, cardprice) {
        return new Promise((resolve, reject) => {
            let addonlimit = 0;
            let addonPrice = cardprice || 0;
            let curFood = item.FoodExtras[idx]
            let addonobj = { "name": name, "price": price, 'title': type, 'maintoppings': [] };
            var obj = { "title": curFood.name.toUpperCase(), 'subtitle': item.title, subaddons: [], "color": color }
            var ele = $event.target;
            for (let x = 0; x < transactionAddons.length; x++) {
                if (transactionAddons[x].title == curFood.name.toUpperCase()) {
                    for (let y = 0; y < transactionAddons[x]['subaddons'].length; y++) {
                        if (transactionAddons[x]['subaddons'][y].name == type) {
                            if (transactionAddons[x]['subaddons'][y]['obj'].length > 0) {
                                addonlimit = transactionAddons[x]['subaddons'][y]['obj'].length
                            }
                        }
                    }
                }
            }
            if (ele.parentNode.style['background-color'] != '') {
                ele.parentNode.style = ``;
                for (let x in transactionAddons) {
                    if (transactionAddons[x].title == curFood.name.toUpperCase()) {
                        for (let y in transactionAddons[x]['subaddons']) {
                            if (transactionAddons[x]['subaddons'][y].name == type) {
                                transactionAddons[x]['subaddons'][y]['obj'].splice(x, 1)
                                item.FoodExtras[idx]['error'] = '';
                                // if (required) {
                                //     isRequired.push(type)
                                // }
                            }
                        }
                    }
                }
            }
            else {
                if (addonlimit != Number(limit)) {
                    if (ele.parentNode.classList.contains('addonBtn')) {
                        ele.parentNode.style = `background:${color};color:white;`;
                        var flag = 1;
                        var addflag = 1;

                        for (let x in transactionAddons) {
                            if (transactionAddons[x].title == curFood.name.toUpperCase()) {
                                addflag = 0;
                                transactionAddons[x]['subaddons'].map(addItem => {
                                    if (addItem.name == type) {
                                        addItem['obj'].push(
                                            addonobj
                                        );
                                        flag = 0;
                                    }
                                })

                            }
                        }

                        if (flag == 1 && addflag == 1) {
                            obj['subaddons'].push({
                                obj: [addonobj],
                                name: type,
                                price: addonPrice
                            })
                            transactionAddons.push(obj)
                        }
                        else if (flag == 1 && addflag == 0) {
                            for (let x in transactionAddons) {
                                if (transactionAddons[x].title == curFood.name.toUpperCase()) {
                                    transactionAddons[x]['subaddons'].push({
                                        obj: [addonobj],
                                        name: type,
                                        price: addonPrice
                                    })
                                }
                            }
                        }
                        if (item.FoodExtras != undefined) {
                            for (let m in item.FoodExtras) {
                                var attribute = item.FoodExtras[m].htmlfordeal;
                                attribute.map(data1 => {
                                    if (type == data1.title) {
                                        data1.FoodExtras.map(data2 => {
                                            if (data2['childPopup']) {
                                                if (data2['childAttribute'] != undefined && (data2.name == name) && data2['childAttribute'].length > 0) {
                                                    addonchild = data2['childAttribute'];
                                                    childAttribute = data2
                                                    this.dialogRef2 = this.dialogService.show(template, {
                                                        class: 'addons_popup'
                                                    })
                                                }
                                            }
                                        })
                                    }
                                })
                            }
                        }
                        // if (required) {
                        //     for (var j = 0; j < isRequired.length; j++) {
                        //         if (isRequired[j] == type) {
                        //             isRequired.splice(j, 1)
                        //         }
                        //     }
                        // }
                    }
                }
                else {
                    item.FoodExtras[idx]['error'] = `Select Any ${limit} ${type} Only!`
                }
            }
            let objnew = {
                childAttribute: childAttribute,
                addonchild: addonchild
            }
            resolve(objnew)
        })
    }

    addAddonChildDealOptionToTransaction(name, price, color, type, $event, required, limit, item, template, idx, transactionAddons, isRequired, childAttribute, addonchild) {
        let addonlimit = 0;
        let curFood = item.FoodExtras[idx]
        let addonobj = { "name": name, "price": price, 'title': type, 'maintoppings': [] };
        var obj = { "title": curFood.name.toUpperCase(), 'subtitle': item.title, subaddons: [], "color": color }
        var ele = $event.target;
        for (let x in transactionAddons) {
            if (transactionAddons[x].title == curFood.name.toUpperCase()) {
                for (let y in transactionAddons[x]['subaddons']) {
                    if (transactionAddons[x]['subaddons'][y].name == type) {
                        addonlimit++
                    }
                }
            }
        }
        if (ele.parentNode.style['background-color'] != '') {
            ele.parentNode.style = ``;
            for (let x in transactionAddons) {
                if (transactionAddons[x].title == curFood.name.toUpperCase()) {
                    for (let y in transactionAddons[x]['subaddons']) {
                        if (transactionAddons[x]['subaddons'][y].name == type) {
                            transactionAddons[x]['subaddons'][y]['obj'].splice(x, 1)
                            item['error'] = '';
                            // if (required) {
                            //     isRequired.push(type)
                            // }
                        }
                    }
                }
            }
        }
        else {
            if (addonlimit != Number(limit)) {
                if (ele.parentNode.classList.contains('addonBtn')) {
                    ele.parentNode.style = `background:${color};color:white;`;
                    var flag = 1;
                    var addflag = 1;

                    for (let x in transactionAddons) {
                        if (transactionAddons[x].title == curFood.name.toUpperCase()) {
                            addflag = 0;
                            transactionAddons[x]['subaddons'].map(addItem => {
                                if (addItem.name == type) {
                                    addItem['obj'].push(
                                        addonobj
                                    );
                                    flag = 0;
                                }
                            })

                        }
                    }

                    if (flag == 1 && addflag == 1) {
                        obj['subaddons'].push({
                            obj: [addonobj],
                            name: type
                        })
                        transactionAddons.push(obj)
                    }
                    else if (flag == 1 && addflag == 0) {
                        for (let x in transactionAddons) {
                            if (transactionAddons[x].title == curFood.name.toUpperCase()) {
                                transactionAddons[x]['subaddons'].push({
                                    obj: [addonobj],
                                    name: type
                                })
                            }
                        }
                    }
                    if (item.FoodExtras != undefined) {
                        for (let m in item.FoodExtras) {
                            var attribute = item.FoodExtras[m].htmlfordeal;
                            attribute.map(data1 => {
                                if (type == data1.title) {
                                    data1.FoodExtras.map(data2 => {
                                        if (data2['childPopup']) {
                                            if (data2['childAttribute'] != undefined && (data2.name == name) && data2['childAttribute'].length > 0) {
                                                addonchild = data2['childAttribute'];
                                                childAttribute = data2
                                                this.dialogRef = this.dialogService.show(template, {
                                                    class: 'addons_popup'
                                                })
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    }
                    // if (required) {
                    //     for (var j = 0; j < isRequired.length; j++) {
                    //         if (isRequired[j] == type) {
                    //             isRequired.splice(j, 1)
                    //         }
                    //     }
                    // }
                }
            }
            else {
                item.FoodExtras[idx]['error'] = `Select Any ${limit} ${type} Only!`
            }
        }
    }

    checkChildAttributeRequirements(addonchild, transactionAddons, childAttribute) {
        var count = 0;
        transactionAddons.map(addonparent => {
            if (addonparent['obj']! + undefined) {
                addonparent.obj.map(addon => {
                    for (var i = 0; i < addon['maintoppings'].length; i++) {
                        addonchild.forEach(item => {
                            if (item.title == childAttribute.child)
                                item.FoodExtras.map(additem => {
                                    if ((addon['maintoppings'][i].name == additem.name) && (addon['maintoppings'][i].type == childAttribute['child'])) {
                                        count++;
                                    }
                                })
                        });


                    }
                })
            }
            else {
                addonparent.subaddons.map(addon => {
                    addon.obj.map(addon => {
                        for (var i = 0; i < addon['maintoppings'].length; i++) {
                            addonchild.forEach(item => {
                                if (item.title == childAttribute.child)
                                    item.FoodExtras.map(additem => {
                                        if ((addon['maintoppings'][i].name == additem.name) && (addon['maintoppings'][i].type == childAttribute['child'])) {
                                            count++;
                                        }
                                    })
                            })

                        }
                    })
                })
            }

        })
        if (childAttribute['isRequired'] && count != Number(childAttribute['limit']) && childAttribute['limit'] != undefined && childAttribute['limit'] != 'nolimit') {
            // item['error'] = `Select Any ${childAttribute['limit']} ${childAttribute['child']} Only!`
            this.toaster.warning(`Select Any ${childAttribute['limit']} ${childAttribute['child']} Only!`)
            return true;
        }
        else if (childAttribute['isRequired'] && (childAttribute['limit'] == 'nolimit' || childAttribute['limit'] == undefined) && count == 0) {
            // item['error'] = `Select Any 1 ${childAttribute['child']}`
            this.toaster.warning(`Select Any 1 ${childAttribute['child']}`)
            return true;
        }
        else {
            return false
            // this.dialogRef2.hide()
        }
    }
}
