import { Component, Input, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { clone } from 'lodash';
import { ToastrService } from 'ngx-toastr';

import { MenuModel } from 'src/app/model/menu';
import { FoodModel, AddonModel } from 'src/app/model/food';
import { OrderedFoodModel, OrderModel } from 'src/app/model/order';
import { OrderService } from 'src/app/service/order.service';
import { FoodExtrasComponent } from 'src/app/shared/components/food-extras/food-extras.component';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit, OnDestroy {

    @Input()
    selectedMenu: MenuModel;
    isOrderAvailable: boolean;
    cartItemList: OrderedFoodModel[] = [];
    public modalRef: BsModalRef;

    constructor(
        private _orderService: OrderService,
        private modalService: BsModalService,
        private toastr: ToastrService
    ) {
        this.checkWorkHour();
    }
    decodeEntities = (function () {
        // this prevents any overhead from creating the object each time
        var element = document.createElement('div');

        function decodeHTMLEntities(str) {
            if (str && typeof str === 'string') {
                // strip script/html tags
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }

            return str;
        }

        return decodeHTMLEntities;
    })();
    ngOnInit() {
        if ((window.innerWidth <= 1024))
            document.getElementById('mobile_sticky_cart').classList.remove('hide');
        else
            document.getElementById('mobile_sticky_cart').classList.add('hide');
        console.log('selectm' + this.selectedMenu.foods);
        if (this.selectedMenu !== undefined) {
            if (this.selectedMenu.foods !== undefined) {
                console.log('selectdescriptionm' + this.selectedMenu.foods[0].description);
                this.selectedMenu.foods[0].description = this.selectedMenu.foods[0].description.replace('poundsym', '&pound;')
                this.selectedMenu.foods[0].description = this.decodeEntities(this.selectedMenu.foods[0].description);
            }
        }
    }

    init(curFoods) {
        let cartItemList: OrderedFoodModel[] = [];
        if (curFoods.length > 0) {
            curFoods.forEach((item: FoodModel) => {
                let temp = {
                    foodAddon: '',
                    foodId: item.id,
                    foodImage: item.image,
                    foodName: item.name,
                    foodPrice: item.price,
                };
                cartItemList.push(new OrderedFoodModel(temp));
            })
        }
        this.cartItemList = cartItemList;
    }

    ngOnChanges(changes: SimpleChanges) {
        const curFoods: FoodModel[] = changes.selectedMenu.currentValue.foods;
        this.init(curFoods)
    }



    // onAddonSelect(item: FoodModel) {
    //     const idx = this.cartItemList.findIndex(u => u.foodId == item.id);
    //     let data = [];
    //     if (idx > -1) {
    //         item.addon.forEach(item => {
    //             if (this.cartItemList[idx].foodAddon.find(u => u.name == item.name)) {
    //                 let temp = {
    //                     ...item,
    //                     isSelected: true
    //                 }
    //                 data.push(temp);
    //             } else {
    //                 let temp = {
    //                     ...item,
    //                     isSelected: false
    //                 }
    //                 data.push(temp);
    //             }
    //         })
    //     } else {
    //         this.toastr.error('Something went wrong!');
    //         return;
    //     }
    //     this.modalRef = this.modalService.show(FoodExtrasComponent, {
    //         initialState: {
    //             title: item.name,
    //             data: data
    //         }
    //     });

    //     this.modalRef.content.onClose.subscribe(result => {
    //         if (!result) return;
    //         const idx = this.cartItemList.findIndex(u => u.foodId == item.id);
    //         if (idx > -1) {
    //             this.cartItemList[idx].foodAddon = result;
    //         } else {
    //             this.cartItemList[idx].foodAddon = [];
    //         }
    //         this.cartItemList[idx].calcExtraPrice();
    //     })
    // }

    getTotlaPrice(index) {
        return this.cartItemList[index].foodPrice + this.cartItemList[index].foodExtraPrice;
    }

    // onAddToCart(index) {
    //     this._orderService.setCartItem(clone(this.cartItemList[index]));
    //     const curFoods: FoodModel[] = this.selectedMenu.foods;
    //     console.log('price' + this.getTotlaPrice(index));
    //     if (document.getElementById('sticky_cart_total')) {
    //         console.log('priceval' + this.getTotlaPrice(index));

    //         document.getElementById('sticky_cart_total').innerHTML = "" + this._orderService.myOrder.totalPayment.toFixed(2);
    //     }
    //     this.init(curFoods)

    //     if ((window.innerWidth <= 1024))
    //         document.getElementById('mobile_sticky_cart').classList.remove('hide');
    //     else
    //         document.getElementById('mobile_sticky_cart').classList.add('hide');

    //     this.toastr.success('Item is added to cart');
    // }

    calcRating(item: FoodModel) {
        return item.calcRating();
    }

    checkWorkHour() {
        // const { scheduleTimes, isServing } = this._orderService.checkWorkHour('');
        // this.isOrderAvailable = isServing;
    }

    ngOnDestroy() {

    }
}
