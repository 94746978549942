import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { Error404Component } from './auth/error404/error404.component';

export const routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
            { path: 'menu', loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule) },
            { path: 'menu-list', loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule) },
            { path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
            { path: 'contact', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
            { path: 'gallery', loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule) },
            // { path: 'franchise', loadChildren: () => import('./shopping/shopping.module').then(m => m.ShoppingModule) },
            { path: 'cart', loadChildren: () => import('./cart/cart.module').then(m => m.CartModule) },
            { path: 'booking', loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule) },
            // { path: 'account-setting', loadChildren: () => import('./myaccount/myaccount.module').then(m => m.MyaccountModule) },
            { path: 'checkout', loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) }
            //{ path: 'checkout', loadChildren: () => import('./basil-checkout/basil-checkout.module').then(m => m.BasilCheckoutModule) }
        ]
    },

    // { path: 'login', component: LoginComponent },
    // { path: 'register', component: RegisterComponent },
    // { path: 'error404', component: Error404Component },

    // Not found
    { path: '**', redirectTo: 'home' }

];
