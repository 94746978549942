import { CONSTANT } from '../service/constant';

// export class OptionModel {
//     name: string;
//     price: number
//     constructor(data?: any) {
//         data = data || {}
//         this.name = data.name || '';
//         this.price = data.price || 0;
//     }

//     toJSON(): {} {
//         const dicObject = Object.assign({}, this);
//         return JSON.parse(JSON.stringify(dicObject));
//     }
// }
export class MaintoppingsModel {
    name: string;
    price: number;
    type: string;
    constructor(data?: any) {
        data = data || {}
        this.name = data.name || '';
        this.price = data.price || 0;
        this.type = data.type || '';
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}

export class ObjModel {
    name: string;
    price: number;
    type: string;
    maintoppings: MaintoppingsModel;
    constructor(data?: any) {
        data = data || {}
        this.name = data.name || '';
        this.price = data.price || 0;
        this.type = data.type || ''
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}

export class SubAddonModel {
    name: string;
    price: number;
    obj: ObjModel[];
    constructor(data?: any) {
        data = data || {}
        this.name = data.name || '';
        this.price = data.price || 0;
        this.obj = data.type || [];
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}

export class ChildAttributeModel {
    name: string;
    price: number;
    child: string;
    childAttribute: any;
    childPopup: boolean;
    constructor(data?: any) {
        data = data || {}
        this.name = data.name || '';
        this.price = data.price || 0;
        this.child = data.child || '';
        this.childAttribute = data.childAttribute || [];
        this.childPopup = data.childPopup || false;
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}

export class FoodExtrasModel {
    name: string;
    price: number;
    child: string;
    childAttribute: any;
    childPopup: boolean;
    constructor(data?: any) {
        data = data || {}
        this.name = data.name || '';
        this.price = data.price || 0;
        this.child = data.child || '';
        this.childAttribute = data.childAttribute || [];
        this.childPopup = data.childPopup || false;
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}



export class AddonModel {
    title: string;
    FoodExtras: any;
    required: boolean;
    limit: string;
    constructor(data?: any) {
        data = data || {}
        this.title = data.title || '';
        this.title = data.title || '';
        this.required = data.required || '';
        this.limit = data.limit || '';
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}

export class OptionModel {
    name: string;
    price: number
    constructor(data?: any) {
        data = data || {}
        this.name = data.name || '';
        this.price = data.price || 0;
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}
export class SizeModel {
    name: string;
    price: number
    constructor(data?: any) {
        data = data || {}
        this.name = data.name || '';
        this.price = data.price || 0;
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}

export class FoodModel {
    id: string;
    name: string;
    image: string;
    description?: string;
    price?: number;
    ratingCount?: number;
    ratingValue?: number;
    addon: AddonModel[];
    rating: number;
    isAlcohol: number;
    size: SizeModel[];
    option: OptionModel[];
    constructor(data: any) {
        data = data || {};
        this.id = data.id || '';
        this.isAlcohol = data.isAlcohol || 0;
        this.name = data.name || '';
        this.image = data.image || CONSTANT.logo;
        this.description = data.description || '';
        this.price = data.price || 0;
        this.ratingCount = data.ratingCount || 0;
        this.ratingValue = data.ratingValue || 0;
        this.addon = data.Attributes || [];



        if (data.description) {
            this.description = this.description.replace('poundsym', '&pound;')
            this.description = this.decodeEntities(this.description);
        } else {
            this.description = '';
        }


        // if (data.addon) {
        //     this.addon = data.addon.map((value: any) => new AddonModel(value));
        // } else {
        //     this.addon = [];
        // }

        // if (data.Attributes) {
        //     this.addon = data.Attributes.map((value: any) => new AddonModel(value));
        // } else {
        //     this.addon = [];
        // }

        if (data.ratingCount == 0) {
            this.rating = 0;
        } else {
            this.rating = data.ratingValue / data.ratingCount;
        }
    }
    decodeEntities = (function () {
        // this prevents any overhead from creating the object each time
        var element = document.createElement('div');

        function decodeHTMLEntities(str) {
            if (str && typeof str === 'string') {
                // strip script/html tags
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent;
                element.textContent = '';
            }

            return str;
        }

        return decodeHTMLEntities;
    })();
    calcRating() {
        return Math.round(this.ratingValue / this.ratingCount);
    }

    // toJSON(): {} {
    //     const dicObject = Object.assign({}, this, {
    //         addon: this.addon ? this.addon.map(value => value.toJSON()) : undefined,
    //     });
    //     return JSON.parse(JSON.stringify(dicObject));
    // }
}
