import { values } from 'lodash';

import { FoodModel } from './food'

export class MenuModel {
    id: string;
    name: string;
    description: string;
    image: string;
    foods: FoodModel[];
    isAlcohol: number;
    constructor(data: any) {
        data = data || {};
        this.isAlcohol = data.isAlcohol || 0;
        this.id = data.id || '';
        this.name = data.name || '';
        this.description = data.description || '';
        this.image = data.image || '';

        if (data.foods) {
            this.foods = data.foods.map(function (value) {
                if (value.isAlcohol != 1) {
                    // return new FoodModel(value)
                }
                else {
                    // return new FoodModel({})
                }

            });
        } else {
            this.foods = [];
        }
    }

    // toJSON(): {} {
    //     const dicObject = Object.assign({}, this, {
    //         foods: this.foods ? this.foods.map(value => value.toJSON()) : {}
    //     });
    //     return JSON.parse(JSON.stringify(dicObject));
    // }
}
