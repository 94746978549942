import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import { CONSTANT } from './constant';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    token = new BehaviorSubject(null);
    availablesmsCredit: any = 0;
    smsFrom: string;

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private _httpClient: HttpClient,
        private _db: AngularFireDatabase,
        private firedb: AngularFirestore,
        private toastr: ToastrService
    ) {
        this.angularFireMessaging.messaging.subscribe((_messaging) => {
            _messaging.onMessage = _messaging.onMessage.bind(_messaging);
            _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
        })
    }

    requestPermission(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.angularFireMessaging.requestToken.subscribe((token) => {
                resolve(token);
            },
                (err) => {
                    console.error('Unable to get permission to notify.', err);
                    reject
                });
        })
    }

    receiveMessage() {
        this.angularFireMessaging.messages.subscribe((payload) => {
            // this.toastr.success(payload?.data?.content, "new message received.");
            this.currentMessage.next(payload);
        })
    }


    sendMessage(message: string) {
        // this._db.list(`${COLLECTION_TOKENS}`).valueChanges()
        // .subscribe((docs: any) => {
        //     const serverToken = docs[0];
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'key=AAAA0DpLFpc:APA91bEGFwiV3CCWtqBQG7QcA07dCSSzaY9gzFWv5XFl4iFl6j1upSAM_xBJZWN0_GVsP0DSK1EZSbaCKJdJ4suZcc_EXgnf4Epf3aUazZlTcY5bpqUSSrNC-oA6WmfE8TtfoA4b4mcB'
            })
        };

        const data = {
            "data": {
                "title": "New Order",
                "content": message
            },
            "to": "/topics/new_order"
        }

        const baseUrl = 'https://fcm.googleapis.com/fcm/send';
        this._httpClient.post(baseUrl, JSON.stringify(data), httpOptions)
            .subscribe((response: any) => {
            })
        // })
    }


    sendOrderSMS(item) {
        let _ = this;
        if (this.availablesmsCredit != undefined && this.availablesmsCredit > 0) {
            const order = item;
            var phoneNumber = order.userPhone
            const orderNumber = order.orderId;
            phoneNumber = '+44' + phoneNumber.substring(phoneNumber.length - 10)


            // Client SMS
            const textMessage = {
                body: 'Order #' + orderNumber + '\n Date : ' + order.scheduledDate + '\n Time : ' + order.scheduledTime + '\n Thank you for your order',
                // body: 'Order #' + orderNumber + '. Please present this text to collect. Thank you for your order.',
                to: phoneNumber, // Text to this number
                from: this.smsFrom, // From a valid Twilio number
                createDate: Date.now(),
                status: 0
            }
            let smsflag1 = 1;
            this.firedb.collection('Messages').add(textMessage).then(() => {
                // this.firedb.collection('Messages').snapshotChanges()
                //     .subscribe(snap => {
                //         snap.forEach(docs => {
                //             let value = docs.payload.doc.data()
                //             if (value['to'] == textMessage.to && docs.type == 'modified') {
                //                 if (value['delivery'] != undefined && value['delivery']['state'] != undefined && value['delivery']['state'] == 'SUCCESS' && smsflag1 == 1) {
                //                     _.updateSMSCount()
                //                     smsflag1 = 0;
                //                 }
                //             }

                //         });

                //     })
            })



            // Admin SMS
            var isPaid = 'Paid';
            if (order.cod)
                isPaid = 'Not paid';
            const textMessage1 = {
                body: 'Order #' + orderNumber + '\n Type : ' + order.OrderType + '\n Date : ' + order.scheduledDate + '\n Time : ' + order.scheduledTime + '\n Name : ' + order.userName + '\n Phone : ' + order.userPhone + '\n Pay Status: ' + isPaid,
                to: '+44' + CONSTANT.To, // Text to this number
                from: this.smsFrom, // From a valid Twilio number
                createDate: Date.now(),
                status: 0
            }

            let smsflag2 = 1;
            this.firedb.collection('Messages').add(textMessage1).then(() => {
                // this.firedb.collection('Messages').snapshotChanges()
                //     .subscribe(snap => {
                //         snap.forEach(docs => {
                //             let value = docs.payload.doc.data()
                //             if (value['to'] == textMessage1.to && docs.type == 'modified') {
                //                 if (value['delivery'] != undefined && value['delivery']['state'] != undefined && value['delivery']['state'] == 'SUCCESS' && smsflag2 == 1) {
                //                     _.updateSMSCount()
                //                     smsflag2 = 0;
                //                 }
                //             }

                //         });

                //     })
            })
        }

    }

    sendBookingSMS(item) {
        let _ = this;
        if (this.availablesmsCredit != undefined && this.availablesmsCredit > 0) {
            const booking = item;
            var phoneNumber = booking.phoneNumber
            const orderNumber = booking.bookingNumber;
            phoneNumber = '+44' + phoneNumber.substring(phoneNumber.length - 10);

            var name = booking.firstName + ' ' + booking.lastName;

            // Client SMS
            const textMessage = {
                body: 'Your booking is confirmed. Booking #' + orderNumber + '\n Date : ' + booking.date + '\n Time : ' + booking.time + '\n Thank you for your booking',
                to: phoneNumber,  // Text to this number
                from: this.smsFrom, // From a valid Twilio number
                createDate: Date.now(),
                status: 2
            }
            let smsflag1 = 1;
            if (booking.optInSmsNotifications)
                this.firedb.collection('Messages').add(textMessage).then(() => {
                    // this.firedb.collection('Messages').snapshotChanges()
                    //     .subscribe(snap => {
                    //         snap.forEach(docs => {
                    //             let value = docs.payload.doc.data()
                    //             if (value['to'] == textMessage.to && docs.type == 'modified') {
                    //                 if (value['delivery'] != undefined && value['delivery']['state'] != undefined && value['delivery']['state'] == 'SUCCESS' && smsflag1 == 1) {
                    //                     _.updateSMSCount()
                    //                     smsflag1 = 0;
                    //                 }
                    //             }

                    //         });

                    //     })
                })


            // Admin SMS
            const textMessage1 = {
                body: 'Your booking is confirmed. Booking #' + orderNumber + '\n Date : ' + booking.date + '\n Time : ' + booking.time + '\n Name : ' + name + '' + '\n Phone : ' + booking.phoneNumber,
                to: '+44' + CONSTANT.To, // Text to this number
                from: this.smsFrom, // From a valid Twilio number
                createDate: Date.now(),
                status: 2 // From a valid Twilio number
            }

            let smsflag2 = 1;
            this.firedb.collection('Messages').add(textMessage1)
                .then(() => {
                    // this.firedb.collection('Messages').snapshotChanges()
                    //     .subscribe(snap => {
                    //         snap.forEach(docs => {
                    //             let value = docs.payload.doc.data()
                    //             if (value['to'] == textMessage1.to && docs.type == 'modified') {
                    //                 if (value['delivery'] != undefined && value['delivery']['state'] != undefined && value['delivery']['state'] == 'SUCCESS' && smsflag2 == 1) {
                    //                     _.updateSMSCount()
                    //                     smsflag2 = 0;
                    //                 }
                    //             }

                    //         });

                    //     })
                })
        }




    }

    getSMSCount() {
        this.firedb.collection('Settings').doc('smsSettings').valueChanges()
            .subscribe(response => {
                if (response != undefined)
                    this.availablesmsCredit = response['smscredits'] || 0
            })
    }

    updateSMSCount() {
        this.availablesmsCredit = (parseInt(this.availablesmsCredit) - 1) <= 0 ? 0 : (parseInt(this.availablesmsCredit) - 1);
        this.firedb.collection('Settings').doc('smsSettings').set({
            smscredits: this.availablesmsCredit
        })
        this.getSMSCount()
    }

    getFromName() {
        this.firedb.collection('Settings').doc('webSettings').valueChanges()
            .subscribe(res => {
                if (res)
                    this.smsFrom = res['smsContactFrom'] || CONSTANT.webTitle
            })
    }
}
