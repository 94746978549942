import { Component, HostBinding, Inject, OnInit, Renderer2 } from '@angular/core';
import { MessagingService } from 'src/app/service/messaging.service';
import { SettingsService } from './core/settings/settings.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
// import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { CONSTANT } from './service/constant';
import { AngularFirestore } from '@angular/fire/firestore';
import { MenuService } from './service/menu.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

    customOptions: OwlOptions = {
        loop: true,
        margin: 0,
        nav: true,
        mouseDrag: true,
        items: 5,
        autoHeight: true,
        dots: true,
        autoplay: false,
        smartSpeed: 500,
        autoplayHoverPause: true,
        navText: [
            "<i class='flaticon-curve-arrow'></i>",
            "<i class='flaticon-curve-arrow-1'></i>",
        ],
    }


    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.getLayoutSetting('isFixed'); };
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); };
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); };
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); };
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); };
    @HostBinding('class.layout-h') get horizontal() { return this.settings.getLayoutSetting('horizontal'); };
    @HostBinding('class.aside-float') get isFloat() { return this.settings.getLayoutSetting('isFloat'); };
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); };
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); };
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); };

    title = 'push-notification';
    message;

    constructor(
        @Inject(DOCUMENT) private document: any,
        public settings: SettingsService,
        private _messagingService: MessagingService,
        // private translate: TranslateService, 
        private titleService: Title,
        private renderer: Renderer2,
        private firedb: AngularFirestore,

    ) {
        // Set Scroll bar Color
        const element = (<CSSStyleSheet>document.styleSheets[11]).cssRules[6];
        const element1 = (<CSSStyleSheet>document.styleSheets[11]).cssRules[7];
        const element2 = (<CSSStyleSheet>document.styleSheets[11]).cssRules[8];
        element['style'].setProperty('background', CONSTANT.color);
        element1['style'].setProperty('background', CONSTANT.addItemColor);
        // element2['style'].setProperty('background', CONSTANT.addItemColor);

        // Set Title of Website
        // this.titleService.setTitle(CONSTANT.webTitle);

    }




    ngOnInit() {
        this._messagingService.getSMSCount()
        this._messagingService.getFromName()
        // var cartcount = localStorage.getItem('cart_count')
        //document.getElementById('cart_count').innerHTML = cartcount;
        // prevent empty links to reload the page
        document.addEventListener('click', e => {
            const target = e.target as HTMLElement;
            if (target.tagName === 'A' && ['', '#'].indexOf(target.getAttribute('href')) > -1)
                e.preventDefault();

            if (target.tagName != 'H5' && target.tagName != 'UL' && target.tagName != 'LI' && target.tagName != 'SPAN' && target.tagName != 'LABEL' && target.tagName != 'INPUT' && target.tagName != 'SMALL' && target.tagName != 'U') {
                let ele1 = document.querySelectorAll('.dropdown-toggle.toggleShow.show')
                let ele2 = document.querySelectorAll('.dropdown-menu.toggleShow.show')

                for (let index = 0; index < ele1.length; index++) {
                    ele1[index].classList.remove('show')
                    ele2[index].classList.remove('show')
                }
            }

        });

    }
}
