
// Mexifun Stripe

export const StripeConfig = {
    apiKey: 'pk_test_51IilI6G8fJbRfZRuPTf3hHH5Z1j1rmd1kCXmRWuSIkZ9DArB1L0WexQlB5YY2HZhQvu0XHp1tZ5w3J6JlQasfQQC00rcxIdLl9',
    secretKey: 'sk_test_51IilI6G8fJbRfZRuNRtjUR1j8DReaH4j5CEcvermgknW8NlljrBovWNx8wOEvFo4jdBGxC5YmtbwHvdLX8rII3r500wATMltae'
}

export const SERVICE = {
    order: true,
    booking: false,
    enableDeliveryOption: false,
    enableCollectionOption: true,
    todaysOrderOnly: false,
    todaysBookingOnly: false,
    withPreOrder: true,
    paybycash: true,
    paybycard: true,
    skippreorderbtn: false,
    enableASAP: false,
    enableMenuList: false
}

export const CONSTANT = {

    // API's
    addressURL: 'https://api.getAddress.io/autocomplete/',
    addressAPI: 'NEu0paD9V0GqvpaiudSiXQ32803',


    homeintro: { link: '/assets/img2/home_1.jpg', title: 'Some Words About Us', subtitle: 'We welcome you to sit back, enjoy eating {{title}}, utilizing the best and freshest ingredients.', content1: 'Good food means good mood. The health benefits of eating {{title}} are surprisingly great as it meets the daily nutrition requirements and fulfills the sudden hunger for complete comfort food that you can have.', content2: 'Steak Feast - Not your typical steak food. Our highly trained chefs and the high quality meat will convince even the most demanding steak lovers. Try with steamed rice for great taste.', content3: '' },
    aboutusintro: { link: 'assets/img2/hero_general.jpg', title: 'Our Story', content: [{ text: 'Good food means good mood. The health benefits of eating {{title}} are surprisingly great as it meets the daily nutrition requirements and fulfills the sudden hunger for complete comfort food that you can have.' }, { text: 'Steak Feast - Not your typical steak food. Our highly trained chefs and the high quality meat will convince even the most demanding steak lovers. Try with steamed rice for great taste.' }] },
    bannerimages: [
        { enable: true, link: 'assets/img2/slides/slide_home_1.jpg' },
        { enable: true, link: 'assets/img2/slides/slide_home_2.jpg' },
        { enable: true, link: 'assets/img2/slides/slide_home_3.jpg' },
    ],
    bannergridimages: [
        { enable: true, link: 'assets/img2/banner_1.jpg', title: 'Our Menu', subtitle: 'View Our Specialites' },
        { enable: true, link: 'assets/img2/banner_2.jpg', title: 'Collection', subtitle: 'Take away food' },
        { enable: true, link: 'assets/img2/banner_3.jpg', title: 'Inside Our Restaurant', subtitle: 'View the Gallery' },
    ],
    allowOrder: true,
    allowBooking: true,

    // Web settings
    webTitle: 'Restaurant',
    postCode: '',
    logo: '/assets/images/core/1.png',
    slogo: '/assets/images/core/11.png',
    color: '',
    headerBtncolor: '',
    addItemColor: '',


    // Notifcations Functions
    From: 'Restaurant',
    To: '0000000000',
    enableOrderSMS: SERVICE.order,
    enableBookingSMS: SERVICE.booking,

    // Contactus Page
    Address: 'Shop Address',
    contactnumber: '0987654321',
    email: 'websiteemail',
    glocation: 'https://www.google.com/maps/@10.782201,78.6682284,15z',

    iframeMap: `
    <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9555272.909733934!2d-13.
            430602410876915!3d54.21849236151423!2m3!1f0!2f0!3f0
            !3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a
            9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2sin!4v1657634
            299727!5m2!1sen!2sin"
             width="600"
             height="450"
             style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
     </iframe>`,

    // Working Hours
    sunday: '00:00 - 00:00',
    monday: '00:00 - 00:00',
    tuesday: '00:00 - 00:00',
    wednesday: '00:00 - 00:00',
    thursday: '00:00 - 00:00',
    friday: '00:00 - 00:00',
    saturday: '00:00 - 00:00',



    // Configuration
    maxMile: '7',
    maxPeople: '6',
    maxPreOrder: 100,
    OrderdaysDisabled: [0, 1, 2, 3, 4, 5, 6],
    OrderdisabledDates: [
        '2020-12-25',
        '2020-12-26'
    ],
    BookingdaysDisabled: [0, 1, 2, 3, 4, 5, 6],
    BookingdisabledDates: [
        '2020-12-25',
        '2020-12-26'
    ],
    popup: false,

    // Social Media
    instagram: 'insta',
    facebook: 'fb',
    twitter: 'twiter',

    // Header Menu
    mainmenu: [
        {
            name: 'About us',
            link: '/about-us',
            enable: true
        },
        {
            name: 'Order Online',
            link: '/menu',
            enable: SERVICE.order,
        },
        {
            name: 'Contact Us',
            link: '/contact',
            enable: true
        },
        {
            name: 'Book a Table',
            link: '/booking',
            enable: SERVICE.booking,
        },
        {
            name: 'Cart',
            link: '/cart',
            enable: false
        }
    ],

    bookingCriteria: [
        {
            time: { h: 12, m: 0 },
            tables: 2,
            people: [4, 4]
        },
        {
            time: { h: 13, m: 0 },
            tables: 1,
            people: [6]
        },
        {
            time: { h: 14, m: 0 },
            tables: 1,
            people: [4]
        },
        {
            time: { h: 18, m: 0 },
            tables: 2,
            people: [6, 4]
        },
        {
            time: { h: 19, m: 0 },
            tables: 2,
            people: [6, 4]
        },
        {
            time: { h: 20, m: 0 },
            tables: 3,
            people: [4, 2, 2]
        }
    ],

}

export const customData = {
    date: '',
    time: '',
}
