import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../core/settings/settings.service';
import { CONSTANT } from '../../service/constant';

@Component({
    selector: '[app-footer]',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    logo: any = CONSTANT.logo || '';
    title: string = CONSTANT.webTitle;
    year: any;
    address: string = CONSTANT.Address

    // Opening Time
    sunday: string = CONSTANT.sunday
    monday: string = CONSTANT.monday
    tuesday: string = CONSTANT.tuesday
    wednesday: string = CONSTANT.wednesday
    thursday: string = CONSTANT.thursday
    friday: string = CONSTANT.friday
    saturday: string = CONSTANT.saturday

    // Social Media
    instagram: string = CONSTANT.instagram;
    facebook: string = CONSTANT.facebook;
    twitter: string = CONSTANT.twitter;

    // Theme Color
    color: string = CONSTANT.color

    constructor(public settings: SettingsService) {
        this.year = new Date().getFullYear()
    }

    ngOnInit() {

    }

}
