import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxSelectModule } from 'ngx-select-ex'
import { ModalModule } from 'ngx-bootstrap/modal';

import { SharedModule } from '../../shared/shared.module';
import { MenuService } from 'src/app/service/menu.service'
import { ShoppingComponent } from './shopping/shopping.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';

const routes: Routes = [
    {
        path: '', 
        component: ShoppingComponent,
        resolve     : {
            data    : MenuService
        }
    },
];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        NgxSelectModule,
        ModalModule.forRoot(),
    ],
    declarations: [
        ShoppingComponent,
        MenuItemComponent,
        ShoppingCartComponent
    ],
    exports: [
        RouterModule
    ],
    providers   : [
        MenuService
    ]
})
export class ShoppingModule { }
