import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { MenuService } from '../../../service/menu.service'
import { OrderService } from 'src/app/service/order.service';
import { MenuModel } from 'src/app/model/menu'

@Component({
    selector: 'app-shopping',
    templateUrl: './shopping.component.html',
    styleUrls: ['./shopping.component.scss']
})
export class ShoppingComponent implements OnInit, OnDestroy {

    isLoading: boolean = false;
    menuList: MenuModel[] = [];
    selectedMenu: MenuModel = null;
    isOrderAvailable: boolean = true;

    workingHour = "";

    // onSelectedMenuChanged: BehaviorSubject<any>;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _menuService: MenuService,
        private _router: Router,
        private _orderService: OrderService
    ) {
        // this.onSelectedMenuChanged = new BehaviorSubject({});
        this._unsubscribeAll = new Subject();
    }
    gotoBottomCart() {
        window.scrollTo(0, document.body.scrollHeight);
    }
    ngOnInit() {
        this.isLoading = true;
        this._menuService.onMenuChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(list => {
                this.menuList = list;
                this.selectedMenu = this.menuList[0];
                console.log('select' + this.selectedMenu);
                if (this.selectedMenu !== undefined) {
                    console.log('selectdescription' + this.selectedMenu.description);
                    this.selectedMenu.description = this.selectedMenu.description.replace('poundsym', '&pound;')
                }
                this.isLoading = false;
            });

        this.checkWorkHour();
        document.getElementById('mobile_sticky_cart').classList.add('hide');
    }

    isSelected(item: MenuModel) {
        if (this.selectedMenu) {
            if (this.selectedMenu.id === item.id) {
                return true
            }
        }
        return false
    }

    onSelectMenu(item: MenuModel) {
        this.selectedMenu = item;
        console.log('select1' + item);
        if (this.selectedMenu !== undefined) {
            console.log('selectdescription1' + item.description);
            this.selectedMenu.description = this.selectedMenu.description.replace('poundsym', '&pound;')
            console.log('selectdescription1after' + item.description);
        }
        if (this.selectedMenu !== undefined)
            this.selectedMenu.description = this.selectedMenu.description.replace('poundsym', '&pound;')
    }

    checkWorkHour() {
        // const { times, isServing } = this._orderService.checkWorkHour('');
        // this.workingHour = this._orderService.getTodayWorkTime();
        // return isServing;
    }

    ngOnDestroy() {

    }
}
