import { FoodModel, AddonModel, SizeModel } from './food'
import moment from 'moment';

export class OrderedFoodModel {
    uid: string;
    foodAddon: AddonModel[];
    foodExtraPrice: number;
    foodId: string;
    foodImage: string;
    foodName: string;
    foodPrice: number;
    foodQuantity: number;
    userPhone: string;
    categoryId: string;
    foodSize: SizeModel[]

    constructor(data: any) {
        data = data || {};
        this.uid = data.uid || '';
        this.categoryId = data.categoryId || '';
        this.foodExtraPrice = data.foodExtraPrice || 0;
        this.foodId = data.foodId || '';
        this.foodImage = data.foodImage || '';
        this.foodName = data.foodName || '';
        this.foodPrice = data.foodPrice || 0;
        this.foodQuantity = data.foodQuantity || 1;
        this.userPhone = data.userPhone || '';

        if (data.foodAddon) {
            if (/^[\],:{}\s]*$/.test(data.foodAddon.replace(/\\["\\\/bfnrtu]/g, '@').
                replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                this.foodAddon = JSON.parse(data.foodAddon);
            } else {
                this.foodAddon = [];
            }
        } else {
            this.foodAddon = [];
        }

        if (data.foodSize) {
            if (/^[\],:{}\s]*$/.test(data.foodSize.replace(/\\["\\\/bfnrtu]/g, '@').
                replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
                replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                this.foodSize = JSON.parse(data.foodSize);
            } else {
                this.foodSize = [];
            }
        } else {
            this.foodSize = [];
        }


        this.calcExtraPrice();
    }

    calcExtraPrice() {
        this.foodExtraPrice = 0;
        // this.foodAddon.map(item => this.foodExtraPrice += item.price);

        return this.foodExtraPrice;
    }

    calcPrice() {
        return (this.foodPrice + this.foodExtraPrice) * this.foodQuantity;
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this, {
            foodAddon: this.foodAddon.length > 0 ? JSON.stringify(this.foodAddon) : [],

        });
        return JSON.parse(JSON.stringify(dicObject));
    }
}

export class OrderModel {
    isReal: boolean;
    cod: boolean;
    orderId: number;
    comment: string;
    discount: number;
    deliveryCharge: number;
    finalPayment: number;
    lat: number;
    lng: number;
    orderStatus: number;
    shippingAddress: string;
    houseNumber: string;
    postCode: string;
    totalPayment: number;
    transactionId: string;
    userId: string;
    userName: string;
    userPhone: string;
    scheduledTime: string;
    scheduledDate: string;
    createDate: string;
    OrderType: string
    cartItemList: OrderedFoodModel[];
    orderKey: string;
    unReadOrder: boolean;
    email: string;
    createDateTime: any;
    constructor(data: any) {
        data = data || {};
        this.isReal = data.isReal || false;
        this.cod = data.cod || true;
        this.orderId = data.orderId || 0;
        this.comment = data.comment || '';
        this.discount = data.discount || 0;
        this.deliveryCharge = data.deliveryCharge || 0;
        this.finalPayment = data.finalPayment || 0;
        this.lat = data.lat || 0;
        this.lng = data.lng || 0;
        this.orderStatus = data.orderStatus || 0;
        this.shippingAddress = data.shippingAddress || '';
        this.houseNumber = data.houseNumber || '';
        this.postCode = data.postCode || '';
        this.totalPayment = data.totalPayment || 0;
        this.transactionId = data.transactionId || 'Cash On Delivery';
        this.userId = data.userId || '';
        this.userName = data.userName || '';
        this.userPhone = data.userPhone || '';
        this.cartItemList = data.cartItemList || [];
        this.scheduledTime = data.scheduledTime || '';
        this.scheduledDate = data.scheduledDate || '';
        this.OrderType = data.OrderType || '';
        this.email = data.email || '';
        this.createDate = moment(new Date()).format('MM/DD/YYYY h:mm A');
        this.createDateTime = Date.now();
        this.orderKey = '';
        this.unReadOrder = true;
    }

    calcTotalPrice() {
        let temp = 0;
        this.cartItemList.forEach(item => {
            temp += item.calcPrice();
        })
        this.totalPayment = temp;
        if (this.discount == 0) {
            this.finalPayment = this.totalPayment;
        } else {
            let temp = this.totalPayment - (this.totalPayment * this.discount) / 100;
            this.finalPayment = Math.abs(temp * 100) / 100;
        }
        return this.totalPayment;
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this, {
            // cartItemList: this.cartItemList.map(value => value.toJSON()),
            createDate: new Date(this.createDate).getTime()
        });
        return JSON.parse(JSON.stringify(dicObject));
    }
}
