import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { takeUntil, map } from 'rxjs/operators';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { } from 'googlemaps';
import {HttpModule, Http, Response} from '@angular/http';
import { ToastrService } from 'ngx-toastr';

import { UserAuthService }  from 'src/app/service/user-auth.service'
import { environment } from 'src/environments/environment'

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    valForm: FormGroup;
    passwordForm: FormGroup;
    @ViewChild('address', {static: false}) searchElementRef;

    constructor(public settings: SettingsService, 
                fb: FormBuilder, 
                private _router: Router,
                private _userAuthService: UserAuthService,
                private modalRef: BsModalRef,
                private _ngZone: NgZone,
                private http: Http,
                private toastr: ToastrService
    ) {
        let password = new FormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
        let certainPassword = new FormControl('', [Validators.required, CustomValidators.equalTo(password)]);

        this.passwordForm = fb.group({
            'password': password,
            'confirmPassword': certainPassword
        });

        this.valForm = fb.group({
            'name': [null, Validators.compose([Validators.required])],
            'email': [null, Validators.compose([Validators.required, CustomValidators.email])],
            'accountagreed': [null, Validators.required],
            'passwordGroup': this.passwordForm,
            'postCode': [null, Validators.compose([Validators.required])],
            'address': [null, Validators.compose([Validators.required])],
            'phone': []
        });
    }

    onCreateAccount(data: any): void{
        this._userAuthService.register(data).then((result)=>{
            this.modalRef.hide();
            this._router.navigate(['home']); 
        }).catch((err)=>{
            this.modalRef.hide();
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        for (let c in this.passwordForm.controls) {
            this.passwordForm.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {

            const data = {
                name: value.name,
                email: value.email,
                password: value.passwordGroup.password,
                address: value.address,
                postCode: value.postCode,
                phone: value.phone
            };

            this.onCreateAccount(data);
        }
    }
    
    searchCode(){
        const postCode = this.valForm.controls['postCode'].value;

        if (!postCode || postCode == '') {
            this.toastr.error('Please input postal code.');
            document.getElementById('postal-code').focus();
            return;
        }

        // get user's current location
        this.CallGeoAPI().subscribe(res=>{
            const result = res[0];
            if (!result) {
                this.toastr.error('Invalid code!');
                this.valForm.controls['postCode'].setValue('');
                this.valForm.controls['address'].setValue('');
                document.getElementById('postal-code').focus();
                return;
            }
            this.valForm.controls['address'].setValue(result.formatted_address);
        })
    }

    CallGeoAPI(){
        const postCode = this.valForm.controls['postCode'].value;
        let apiURL = `https://maps.googleapis.com/maps/api/geocode/json?address=${postCode}&key=${environment.googleConfig.apiKey}`;
        return this.http.get(apiURL).pipe(map((response: Response)=>{
            return response.json().results;
        }))
    }

    ngOnInit() {
        document.getElementById('mobile_sticky_cart').classList.add('hide');
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const autocomp = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                types: [],
            })
    
            autocomp.addListener('place_changed', () => {
                this._ngZone.run(() => {
                    const place: google.maps.places.PlaceResult = autocomp.getPlace();
                    if (place.geometry === undefined || place.geometry === null){
                        return;
                    }
                });
            });
        }, 1000);
    }
}
