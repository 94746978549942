import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Title } from '@angular/platform-browser';
import { CONSTANT, SERVICE, StripeConfig } from './service/constant';

@Injectable()
export class AppInitService {
    startTime: string = '';
    endTime: string = '';
    constructor(private firedb: AngularFirestore,
        private titleService: Title,
        @Inject(DOCUMENT) private document: any,
    ) {

    }
    // This is the method you want to call at bootstrap
    // Important: It should return a Promise

    init(): Promise<any> {
        let data;
        return new Promise((resolve, reject) => {
            this.firedb.collection('AccessControl').get()
                .subscribe((snap: any) => {
                    snap.forEach(doc => {
                        let value = doc.data()

                        if (value.webOrder != undefined)
                            SERVICE.order = value.webOrder

                        if (value.webBooking != undefined)
                            SERVICE.booking = value.webBooking

                        if (value.webMenuList != undefined)
                            SERVICE.enableMenuList = value.webMenuList


                    });
                    resolve(snap)
                });

            this.firedb.collection('Configuration').get()
                .subscribe((snap: any) => {
                    snap.forEach(doc => {
                        let value = doc.data()

                        if (value.maxMiles != undefined)
                            CONSTANT.maxMile = value.maxMiles

                        if (value.maxPreorder != undefined)
                            CONSTANT.maxPreOrder = value.maxPreorder

                        if (value.maxPeople != undefined)
                            CONSTANT.maxPeople = value.maxPeople

                        if (value.disabledBookingDates != undefined)
                            CONSTANT.BookingdisabledDates = value.disabledBookingDates

                        if (value.name != undefined && value.name == 'allowBooking')
                            CONSTANT.allowBooking = value.allow

                        if (value.name != undefined && value.name == 'allowOrder')
                            CONSTANT.allowOrder = value.allow


                    });
                    resolve(snap)
                })

            this.firedb.collection('Settings').get()
                .subscribe((snap: any) => {
                    snap.forEach(doc => {
                        let value = doc.data()
                        if (value.startTime != undefined && value.startTime != '') {
                            this.startTime = value.startTime
                        }
                        if (value.endTime != undefined && value.endTime != '') {
                            this.endTime = value.endTime
                        }
                    })
                    if (this.startTime != '' && this.endTime != '') {
                        let ind = ' - '
                        for (let index = 0; index < this.startTime.length; index++) {
                            const element = this.startTime[index];
                            if (element == '-') {
                                ind = ' & '
                            }
                        }
                        let time = this.startTime + ind + this.endTime
                        CONSTANT.sunday = time
                        CONSTANT.monday = time
                        CONSTANT.tuesday = time
                        CONSTANT.wednesday = time
                        CONSTANT.thursday = time
                        CONSTANT.friday = time
                        CONSTANT.saturday = time
                    }
                })

            this.firedb.collection('Settings').get()
                .subscribe((snap: any) => {
                    snap.forEach(doc => {
                        let value = doc.data()



                        if (value.name != undefined) {
                            CONSTANT.webTitle = value.name
                            this.titleService.setTitle(value.name);
                        }

                        if (value.map != undefined) {
                            CONSTANT.iframeMap = value.map

                        }

                        if (value.address != undefined) {
                            CONSTANT.Address = value.address
                        }
                        if (value.postcode != undefined) {
                            CONSTANT.postCode = value.postcode
                        }
                        if (value.phone != undefined) {
                            CONSTANT.contactnumber = value.phone
                        }
                        if (value.email != undefined) {
                            CONSTANT.email = value.email
                        }
                        if (value.logo != undefined) {
                            CONSTANT.logo = value.logo
                            CONSTANT.slogo = value.logo
                            this.document.getElementById('appFavicon').setAttribute('href', CONSTANT.logo)
                        }
                        if (value.paybycash != undefined) {
                            SERVICE.paybycash = value.paybycash
                        }
                        if (value.paybycard != undefined) {
                            SERVICE.paybycard = value.paybycard
                        }
                        if (value.continuwithpreorder != undefined) {
                            SERVICE.withPreOrder = value.continuwithpreorder
                        }
                        if (value.enableCollection != undefined) {
                            SERVICE.enableCollectionOption = value.enableCollection;
                        }
                        if (value.enableDelivery != undefined) {
                            SERVICE.enableDeliveryOption = value.enableDelivery;
                        }
                        if (value.skippreorder != undefined) {
                            SERVICE.skippreorderbtn = value.skippreorder;
                        }
                        if (value.enableASAP != undefined) {
                            SERVICE.enableASAP = value.enableASAP;
                        }
                        if (value.facebook != undefined) {
                            CONSTANT.facebook = value.facebook
                        }
                        if (value.instagram != undefined) {
                            CONSTANT.instagram = value.instagram
                        }
                        if (value.twitter != undefined) {
                            CONSTANT.twitter = value.twitter
                        }
                        if (value.smsContact != undefined) {
                            CONSTANT.To = value.smsContact
                        }
                        if (value.shopClosingday != undefined && value.shopClosingday.length > 0) {
                            value.shopClosingday.forEach(day => {
                                if (day == 'Sunday' || day == 'Monday' || day == 'Tuesday' || day == 'Wednesday' || day == 'Thursday' || day == 'Friday' || day == 'Saturday') {
                                    let daySetting = String(day).toLowerCase()
                                    CONSTANT[daySetting] = 'CLOSED'
                                }
                            });
                        }
                        if (value.preBooking != undefined) {
                            SERVICE.todaysBookingOnly = value.preBooking ? false : true
                        }
                        if (value.preOrder != undefined) {
                            SERVICE.todaysOrderOnly = value.preOrder ? false : true
                        }
                        if (value.stripe_apiKey != undefined && value.stripe_apiKey != '') {
                            StripeConfig.apiKey = value.stripe_apiKey
                        }
                        if (value.secretKey != undefined && value.secretKey != '') {
                            StripeConfig.secretKey = value.secretKey
                        }
                        if (value.bannerimages != undefined && value.bannerimages.length > 0) {
                            CONSTANT.bannerimages = value.bannerimages
                        }
                        if (value.bannergridimages != undefined && value.bannergridimages.length > 0) {
                            CONSTANT.bannergridimages = value.bannergridimages
                        }
                        if (value.homeintro != undefined && value.homeintro != '') {
                            CONSTANT.homeintro = value.homeintro
                        }
                        if (value.aboutusintro != undefined && value.aboutusintro != '') {
                            CONSTANT.aboutusintro = value.aboutusintro
                        }
                    })
                })
            return;
        })
    }


}