import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { COLELCTION_FOODS, COLLECTION_CATEGORY } from 'src/app/model/constants';
import { COLLECTION_CONFIGURATION } from 'src/app/model/constants';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { MenuModel } from 'src/app/model/menu';
import { ConfigurationModel } from 'src/app/model/configuration'
import { AngularFirestore } from '@angular/fire/firestore';
import { FoodModel } from '../model/food';
import { CONSTANT } from './constant';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    menuItems: MenuModel[];

    onMenuChanged: BehaviorSubject<any>;
    onMenuItemChanged: BehaviorSubject<any>;

    startAt: number = 1
    endAt: number = 2
    constructor(
        private _db: AngularFirestore,
    ) {
        this.menuItems = [];
        this.onMenuChanged = new BehaviorSubject({});
        this.onMenuItemChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            // this.getMenus(this.startAt, this.endAt)
            //     .catch(() => {
            //     })
            // Promise.all([
            // ]).then(
            //     () => {
            //         resolve();
            //     },
            //     reject
            // );
            resolve()
        });
    }


    getMenus(): Promise<any> {
        let _this = this;
        return new Promise((resolve, reject) => {
            this._db.collection(`${COLLECTION_CATEGORY}`, ref => ref.orderBy('position', 'asc')).valueChanges()
                .subscribe((docs: any) => {
                    this.menuItems = [];

                    docs.forEach(doc => {
                        if (doc['showWeb']) {
                            const item = new MenuModel(doc);
                            if (item.isAlcohol == 0) {
                                // _this.getMenuFoods(item.id).then(data => {
                                item['foods'] = []
                                // if (item['foods'].length > 0)
                                _this.menuItems.push(item);

                                resolve(this.menuItems)
                                // })
                            }
                        }

                    });
                    // this.onMenuChanged.next(this.menuItems);

                }, reject)
            return this.menuItems;
        })
    }
    getMenuFoods(cateId): Promise<any> {
        let menuFood = [];
        return new Promise((resolve, reject) => {
            this._db.collection(`${COLELCTION_FOODS}`, ref => ref.where('categoryId', '==', cateId)).valueChanges()
                .subscribe((docs: any) => {
                    menuFood = [];

                    docs.forEach(doc => {
                        // const item = new FoodModel(doc);
                        const item = doc;
                        if (item.image == '' || item.image == undefined) {
                            item.image = CONSTANT.logo
                        }

                        if (item.name != 'Misc Product' && item.showonweb != false) {
                            menuFood.push(item);
                        }
                    });
                    this.onMenuChanged.next(this.menuItems);
                    resolve(menuFood)
                }, reject)
            return menuFood;
        })
    }

}
