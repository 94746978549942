﻿// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { firebaseConfig } from "../assets/environment";

export const environment = {
  modeTitle: 'environment',
  production: false,
  firebaseConfig: firebaseConfig,
  googleConfig: {
    apiKey: 'AIzaSyCzPraGiYde-bqKxkCgiETLDXov135IKbA',
    libraries: ['places']
  },
  // stripeConfig: {
  //   apiKey: 'pk_test_51IilI6G8fJbRfZRuPTf3hHH5Z1j1rmd1kCXmRWuSIkZ9DArB1L0WexQlB5YY2HZhQvu0XHp1tZ5w3J6JlQasfQQC00rcxIdLl9'
  // },
  payment: {
    paypal: {
      clientId: 'AW4hw_DKB9QrusecWSeMeCAosIJ2oU1A4wtuQUOxOr3FB8t2LNdWHOZ9PdnSjU-lB9EeK2KYB1GvV3tK'
    }
  }
};
