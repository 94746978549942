import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { FBUserModel } from '../model/user';
import { promise } from 'protractor';

@Injectable({
    providedIn: 'root'
})

export class AppService {
    public curUser: FBUserModel;   // Current User Information

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _router: Router,
    ) {
        // Set the default
        this.curUser = new FBUserModel('', {});
    }

    setCurUser(user: FBUserModel): void {
        this.curUser = user;
    }
    getCurUser(): FBUserModel {
        return this.curUser;
    }
    getUserUid(): string {
        return this.curUser.uid;
    }

    onChangedCurUser(curUser: FBUserModel): void {
        this.curUser = curUser;
    }

    isConnetionExist() {
        return new Promise((res, rej) => {
            const _ = this;
            var xhr = new XMLHttpRequest();
            var file = "https://i.imgur.com/7ofBNix.png";
            var randomNum = Math.round(Math.random() * 10000);

            xhr.open('HEAD', file + "?rand=" + randomNum, true);
            xhr.send();

            xhr.addEventListener("readystatechange", processRequest, false);
            function processRequest(e) {
                if (xhr.readyState == 4) {

                    if (xhr.status >= 200 && xhr.status < 304) {
                        // Internet Connected
                        res(1)
                    }
                    else {
                        // No Internet Connection
                        res(0)
                    }
                }
            }
        })
    }
}