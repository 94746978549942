import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        window.addEventListener('scroll', this.scrollEvent, true);        
    }
    gotoBottomCart(){
        window.scrollTo(0,document.body.scrollHeight);
    }
    scrollEvent(ev) {
        var elm = document.getElementsByClassName('footer-container')[0];
        //if (elm.scrollTop + elm.clientHeight == elm.scrollHeight) {
           // console.log('window.innerHeight'+window.innerHeight)
           // console.log('window.pageYOffset'+window.pageYOffset)
           // console.log('document.body.offsetHeight'+ document.getElementsByClassName('section-container')[0].clientHeight)
        if ((window.innerHeight + window.pageYOffset) >= document.getElementsByClassName('section-container')[0].clientHeight) {
            document.getElementById('mobile_sticky_cart').setAttribute('style','bottom:62px')
        }
        else {
            document.getElementById('mobile_sticky_cart').setAttribute('style','bottom:0')
        }
    }

}
