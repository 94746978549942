import moment from 'moment';
export class CustomerModel {
    name: string;
    phonenumber: string;
    postcode: string;
    address: string;
    houseNumber: string;
    createDateTime: number;
    constructor(data?: any) {
        data = data || {}

        this.name = data.name || '';
        this.phonenumber = data.phonenumber || '';
        this.postcode = data.postcode || '';
        this.address = data.address || '';
        this.houseNumber = data.houseNumber || '';
        if (data.createDateTime) {
            this.createDateTime = moment(new Date(data.createDate)).valueOf();
        } else {
            this.createDateTime = moment(new Date()).valueOf();
        }
    }

    toJSON(): {} {
        const dicObject = Object.assign({}, this);
        return JSON.parse(JSON.stringify(dicObject));
    }
}